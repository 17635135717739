import Argument = require("Everlaw/Argument");
import AutoCodeRule = require("Everlaw/AutoCodeRule");
import Base = require("Everlaw/Base");
import Chronology = require("Everlaw/Chron/Chronology");
import ChronCategory = require("Everlaw/Chron/ChronologyCategory");
import ChronLabel = require("Everlaw/Chron/ChronologyLabel");
import Binder = require("Everlaw/Binder");
import Category = require("Everlaw/Category");
import ClusterInclusion = require("Everlaw/Clustering/ClusterInclusion");
import FormatType = require("Everlaw/FormatType");
import Document = require("Everlaw/Document");
import DocumentMutator = require("Everlaw/DocumentMutator");
import Eca = require("Everlaw/Context/Eca");
import Eql = require("Everlaw/Eql");
import Freeform = require("Everlaw/Freeform");
import { NoteUtil } from "Everlaw/Note";
import Perm = require("Everlaw/PermissionStrings");
import ProcessingDataset = require("Everlaw/Processing/ProcessingDataset");
import ProcessingDefs = require("Everlaw/Processing/ProcessingDefs");
import Project = require("Everlaw/Project");
import PredictionModel = require("Everlaw/PredictionModel");
import Production = require("Everlaw/Production/Production");
import ProductionJob = require("Everlaw/Production/ProductionJob");
import RatingConflictType = require("Everlaw/RatingConflictType");
import Redaction = require("Everlaw/Redaction");
import Str = require("Everlaw/Core/Str");
import { SystemPermission } from "Everlaw/SystemPermission";
import Type = require("Everlaw/Type");
import User = require("Everlaw/User");
import PromotionReason = require("Everlaw/PromotionReason");
import ChronologyObject from "Everlaw/Chron/Doc/ChronologyObject";

/*
 * These are imported with underscores to avoid naming conflicts with the classes in this file.
 *
 * For example, below we define a class Code (which is accessed by outside callers as Property.Code).
 */
import _Assignment = require("Everlaw/Assignment");
import _AssignmentGroup = require("Everlaw/AssignmentGroup");
import _Bates = require("Everlaw/Bates");
import _Code = require("Everlaw/Code");
import _DocType = require("Everlaw/DocType");
import _Language = require("Everlaw/Language");
import _Metadata = require("Everlaw/Metadata");
import _Rating = require("Everlaw/Rating");
import _RatingConflict = require("Everlaw/RatingConflict");
import _SearchTermReport = require("Everlaw/SearchTermReport");
import _Upload = require("Everlaw/Upload");

/**
 *  Properties correspond directly to Java properties.
 */
module Property {
    // This type sadly needs to be defined here because of an unbreakable circular dependency: EqlType
    // needs to access all of Property to convert from a raw data structure to an Eql object, and
    // Property needs EqlType because many properties use it. After trying various other configurations,
    // I settled on this one as the least disruptive.
    class EqlTypeClass extends Type.Type {
        override toJsonValue(value: Eql.Any) {
            return value.toJson();
        }
        override fromJsonValue(value: any) {
            return jsonToEql(value);
        }
        override isValidValue(value: any) {
            return value instanceof Eql;
        }
    }
    var EqlType = new EqlTypeClass("Eql");

    export class And extends Eql<Eql.Any[]> {
        static override ARG_TYPE_STRUCTURE = new Type.List(EqlType);
        override matches(doc: Document, mutator: DocumentMutator) {
            return this.args.every((eql) => eql.matches(doc, mutator));
        }
    }

    export class Or extends Eql<Eql.Any[]> {
        static override ARG_TYPE_STRUCTURE = new Type.List(EqlType);
        override matches(doc: Document, mutator: DocumentMutator) {
            return this.args.some((eql) => eql.matches(doc, mutator));
        }
    }

    /**
     * These properties only have one argument: the child property which they wrap around.
     */
    const wrapperPropsWithoutArgs = [
        "not",
        "excludeDuplicates",
        "inclusiveEmails",
        "exactEmailDuplicates",
        "includeOneDuplicate",
    ];

    const groupedWrappers = [
        "flattenGroups",
        "pluckParent",
        "pluckChildren",
        "pluckConversations",
        "searchHits",
        "groupedHits",
    ];

    /**
     * Properties which wrap around another property.
     */
    const wrapperProps = [...wrapperPropsWithoutArgs, ...groupedWrappers, "randomSample"];

    export function isWrapper(propertyName: string): boolean {
        return wrapperProps.indexOf(propertyName) >= 0;
    }

    export function isWrapperWithoutArgs(propertyName: string): boolean {
        return wrapperPropsWithoutArgs.indexOf(propertyName) >= 0;
    }

    export class Not extends Eql<Eql.Any> {
        static override ARG_TYPE_STRUCTURE = EqlType;
        override matches(doc: Document, mutator: DocumentMutator) {
            return !this.args.matches(doc, mutator);
        }
        static negateProperty(eql: Eql<any>) {
            if (eql instanceof Not) {
                let inner = eql.args;
                inner.termName = eql.termName;
                return inner;
            } else {
                return new Not(eql, eql.termName);
            }
        }
    }

    export class ExcludeDuplicates extends Eql<Eql.Any> {
        static override ARG_TYPE_STRUCTURE = EqlType;
    }

    export class IncludeOneDuplicate extends Eql<Eql.Any> {
        static override ARG_TYPE_STRUCTURE = EqlType;
    }

    /**
     * inclusiveEmails does not store a groupType, but a user should only be able to chose the
     * "Remove Non-Inclusive Emails" on the search page after choosing to group by Email Threads.
     * This is different from the backend, where InclusiveEmails extends GroupedLogicalWrapper.
     */
    export class InclusiveEmails extends Eql<Eql.Any> {
        static override ARG_TYPE_STRUCTURE = EqlType;
    }

    export class ExactEmailDuplicates extends Eql<Eql.Any> {
        static override ARG_TYPE_STRUCTURE = EqlType;
    }

    export class RandomSample extends Eql<{ property: Eql.Any; percent: number; seed: number }> {
        static override ARG_TYPE_STRUCTURE = {
            property: EqlType,
            percent: new Type.NumberType("NonNegativeNumber", 0, 100),
            seed: Type.NUMBER,
        };
    }

    /**
     * Wrapper properties which also have a groupType. (See DocumentGroupType.java)
     */
    export interface GroupedWrapper extends Eql<{ groupType: string; property: Eql.Any }> {}

    export function isGroupedWrapper(propertyName: string) {
        return groupedWrappers.indexOf(propertyName) >= 0;
    }

    export class FlattenGroups
        extends Eql<{ groupType: string; property: Eql.Any }>
        implements GroupedWrapper
    {
        static override ARG_TYPE_STRUCTURE = {
            // After discussing with Jeff, we decided to keep this as Text instead of creating an
            // enumeration
            groupType: Type.TEXT,
            property: EqlType,
        };
    }
    export class PluckParent
        extends Eql<{ groupType: string; property: Eql.Any }>
        implements GroupedWrapper
    {
        static override ARG_TYPE_STRUCTURE = {
            groupType: Type.TEXT,
            property: EqlType,
        };
    }
    export class PluckChildren
        extends Eql<{ groupType: string; property: Eql.Any }>
        implements GroupedWrapper
    {
        static override ARG_TYPE_STRUCTURE = {
            groupType: Type.TEXT,
            property: EqlType,
        };
    }
    export class PluckConversations
        extends Eql<{ groupType: string; property: Eql.Any }>
        implements GroupedWrapper
    {
        static override ARG_TYPE_STRUCTURE = {
            groupType: Type.TEXT,
            property: EqlType,
        };
    }
    export class SearchHits
        extends Eql<{ groupType: string; property: Eql.Any }>
        implements GroupedWrapper
    {
        static override ARG_TYPE_STRUCTURE = {
            groupType: Type.TEXT,
            property: EqlType,
        };
    }
    export class GroupedHits
        extends Eql<{ groupType: string; property: Eql.Any }>
        implements GroupedWrapper
    {
        static override ARG_TYPE_STRUCTURE = {
            groupType: Type.TEXT,
            property: EqlType,
        };
    }

    // A type that allows arbitrary JSON
    class JSONType extends Type.Type {
        override displayValue(value: any) {
            return JSON.stringify(value);
        }
        override isValidValue(value: any) {
            return true;
        }
    }
    export class Invalid extends Eql<any> {
        static override ARG_TYPE_STRUCTURE = new JSONType("JSON");
        override toJson() {
            return this.args;
        }
    }

    export class IncompleteNonlogical extends Eql<null> {}

    export class IncompleteMetadata extends Eql<{ field: _Metadata.Field }> {
        static override ARG_TYPE_STRUCTURE = {
            field: new Type.Object(_Metadata.Field),
        };
    }

    export class Promotion extends Eql<PromotionReason> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(PromotionReason);
        static override isAccessible() {
            return Eca.inContext();
        }
    }

    abstract class AbstractCode extends Eql<_Code> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(_Code);

        static override isAccessible() {
            return _Code.canReadSome(User.Override.ELEVATED_OR_ORGADMIN);
        }

        override matches(doc: Document, mutator: DocumentMutator) {
            var code = this.args;
            return code.id in mutator.codes
                ? mutator.codes[code.id]
                : doc.codes.indexOf(code.id) > -1;
        }
    }

    abstract class AbstractCodeCategory extends Eql<Category> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(Category);

        static override isAccessible() {
            return _Code.canReadSome(User.Override.ELEVATED_OR_ORGADMIN);
        }

        override matches(doc: Document, mutator: DocumentMutator) {
            return this.args.codes.some((code) => {
                var id = code.id;
                return id in mutator.codes ? mutator.codes[id] : doc.codes.indexOf(id) > -1;
            });
        }
    }

    export class Code extends AbstractCode {}

    export class CodeExclusive extends AbstractCode {}

    export class CodeCategory extends AbstractCodeCategory {}

    export class CodeCategoryExclusive extends AbstractCodeCategory {}

    export interface CodingSuggestionArgs {
        suggestionType: boolean | null;
        actionable: boolean | null;
    }

    export interface SuggestedCodeArgs extends _Code, CodingSuggestionArgs {}

    export interface SuggestedCodeCategoryArgs extends Category, CodingSuggestionArgs {}

    export class SuggestedCode extends Eql<SuggestedCodeArgs> {
        static override ARG_TYPE_STRUCTURE = {
            id: new Type.Object(_Code),
            suggestionType: new Type.Optional(Type.BOOLEAN),
            actionable: new Type.Optional(Type.BOOLEAN),
        };

        static displayValue(args: any) {
            return args.id.display();
        }

        static override isAccessible() {
            return _Code.canReadSome(User.Override.ELEVATED_OR_ORGADMIN);
        }

        override matches(doc: Document, mutator: DocumentMutator) {
            let code = this.args;
            return doc.getCodeIdsForSuggestions().indexOf(code.id) > -1;
        }
    }

    export class SuggestedCodeCategory extends Eql<SuggestedCodeCategoryArgs> {
        static override ARG_TYPE_STRUCTURE = {
            id: new Type.Object(Category),
            suggestionType: new Type.Optional(Type.BOOLEAN),
            actionable: new Type.Optional(Type.BOOLEAN),
        };
        override matches(doc: Document, mutator: DocumentMutator) {
            let category = this.args;
            return doc.getCodeIdsForSuggestions().indexOf(category.id) > -1;
        }
    }

    export class HasSuggestedCodes extends Eql<CodingSuggestionArgs> {
        static override ARG_TYPE_STRUCTURE = {
            suggestionType: new Type.Optional(Type.BOOLEAN),
            actionable: new Type.Optional(Type.BOOLEAN),
        };
        static override isAccessible() {
            return _Code.canReadSome(User.Override.ELEVATED_OR_ORGADMIN);
        }
        override matches(doc: Document, mutator: DocumentMutator) {
            return doc.getCodeIdsForSuggestions().length > 0;
        }
    }

    export class ViolatesMutuallyExclusiveCategory extends Eql<Category> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(Category);
    }

    export class ViolatesAutoCodeRule extends Eql<AutoCodeRule> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(AutoCodeRule);
    }

    export class AutoCoded extends Eql<AutoCodeRule> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(AutoCodeRule);
        static override isAccessible() {
            return User.me.can(Perm.ADMIN, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);
        }
    }

    export class Tag extends Eql<Binder> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(Binder);
        override matches(doc: Document, mutator: DocumentMutator) {
            var binderId = this.args.id;
            return binderId in mutator.binders
                ? mutator.binders[binderId]
                : doc.binders.indexOf(binderId) > -1;
        }
    }

    export class ViewedBy extends Eql<{
        id?: User;
        group?: User.Group;
    }> {
        static override ARG_TYPE_STRUCTURE = {
            id: new Type.Optional(new Type.Object(User)),
            group: new Type.Optional(new Type.Object(User.Group)),
        };
    }

    export class UserHasAccess extends Eql<User> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(User);
    }

    export class GroupHasAccess extends Eql<User.Group> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(User.Group);
    }

    export class InProject extends Eql<Project> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(Project);
        static override validate(p: Project) {
            return (
                p.databaseId === Project.CURRENT.databaseId
                && p.partial
                && (User.me.has(SystemPermission.MANAGE_PROJECTS)
                    || (!p.suspended
                        && !p.deleted
                        && !p.deletionRequested
                        && User.me.can(Perm.READ, p, User.Override.ORGADMIN)))
            );
        }
    }

    /**
     * Chronology based properties.
     *
     * Properties prefixed with "doc" should be used in searches that want doc ids. Properties prefixed
     * with "chronObject" should be used in searches that want chron object ids. For more information
     * about this, see BaseDocumentChronProp.java.
     */

    export class DocArgument extends Eql<Argument> {
        static override EQL_NAME = "argument";
        static override ARG_TYPE_STRUCTURE = new Type.Object(Argument);
    }

    export class ChronObjectArgument extends Eql<Argument> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(Argument);
    }

    export class DocChronId extends Eql<Chronology> {
        static override EQL_NAME = "chronId";
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_STORYBUILDER,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
        static override ARG_TYPE_STRUCTURE = new Type.Object(Chronology);
    }

    export class ChronObjIds extends Eql<{ chronId: Chronology.Id; ids: ChronologyObject.Id[] }> {
        static override DISPLAY_NAME = "Storybuilder Docs";
        static override ARG_TYPE_STRUCTURE = {
            chronId: Type.NUMBER,
            ids: new Type.List(Type.NUMBER),
        }; // Document IDs
    }

    export class ChronObjectChronId extends Eql<Chronology> {
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_STORYBUILDER,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
        static override ARG_TYPE_STRUCTURE = new Type.Object(Chronology);
    }

    export class DocChronCategory extends Eql<ChronCategory> {
        static override EQL_NAME = "chronCategory";
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_STORYBUILDER,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
        static override ARG_TYPE_STRUCTURE = new Type.Object(ChronCategory);
    }

    export class DocChronLabel extends Eql<ChronLabel> {
        static override EQL_NAME = "chronLabel";
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_STORYBUILDER,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
        static override ARG_TYPE_STRUCTURE = new Type.Object(ChronLabel);
    }

    export class ChronObjectLabel extends Eql<ChronLabel> {
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_STORYBUILDER,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
        static override ARG_TYPE_STRUCTURE = new Type.Object(ChronLabel);
    }

    export class ChronObjectContents extends Eql<{ query: string; chronId: Chronology.Id }> {
        static override EQL_NAME = "chronContents";
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_STORYBUILDER,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
        static override ARG_TYPE_STRUCTURE = {
            query: Type.TEXT,
            chronId: Type.NUMBER,
        };
    }

    export class ChronObjectDate extends Eql<{
        range: Type.DateTimeSearch;
        chronId: Chronology.Id;
    }> {
        static override EQL_NAME = "chronDate";
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_STORYBUILDER,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
        static override ARG_TYPE_STRUCTURE = {
            range: Type.DATE_TIME_SEARCH,
            chronId: Type.NUMBER,
        };
    }

    export class ChronObjectType extends Eql<{ objType: string; chronId: Chronology.Id }> {
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_STORYBUILDER,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
        static override ARG_TYPE_STRUCTURE = {
            objType: Type.TEXT,
            chronId: Type.NUMBER,
        };
    }

    export interface DateAddedToChronArgs {
        dateRange?: Type.DateTimeSearch;
        relativeDate?: Type.RelativeDate;
    }
    export class ChronObjectDateAdded extends Eql<DateAddedToChronArgs> {
        static override EQL_NAME = "dateAddedToChron";
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_STORYBUILDER,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
        static override ARG_TYPE_STRUCTURE = {
            dateRange: new Type.Optional(Type.DATE_TIME_SEARCH),
            relativeDate: new Type.Optional(Type.RELATIVE_DATE),
        };
    }

    export class AssignedTo extends Eql<{ user: User; assignmentGroup?: _AssignmentGroup }> {
        static override ARG_TYPE_STRUCTURE = {
            user: new Type.Optional(new Type.Object(User)),
            assignmentGroup: new Type.Optional(new Type.Object(_AssignmentGroup)),
        };
    }

    export class Assignment extends Eql<_Assignment> {
        static override ARG_TYPE_STRUCTURE = new Type.Optional(new Type.Object(_Assignment));
    }

    export class AssignmentGroup extends Eql<_AssignmentGroup> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(_AssignmentGroup);
    }

    export class Reviewed extends Eql<_AssignmentGroup> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(_AssignmentGroup);
    }

    export class UnassignedInStaticGroup extends Eql<null> {}

    export class UnassignedInvalid extends Eql<_AssignmentGroup> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(_AssignmentGroup);
    }

    export class Contents extends Eql<string> {
        static override ARG_TYPE_STRUCTURE = Type.TEXT;
    }

    export class Predictable extends Eql<null> {}

    export interface AllTextFieldsArgs {
        value: string;
        includeDocContents: boolean;
    }

    export class AllTextFields extends Eql<AllTextFieldsArgs> {
        static override ARG_TYPE_STRUCTURE = {
            value: Type.TEXT,
            includeDocContents: Type.BOOLEAN,
        };
    }

    export class AllDateFields extends Eql<Type.DateTimeSearch> {
        static override ARG_TYPE_STRUCTURE = Type.DATE_TIME_SEARCH;
    }

    export class FamilyDate extends Eql<Type.DateTimeSearch> {
        static override ARG_TYPE_STRUCTURE = new Type.Optional(Type.DATE_TIME_SEARCH);

        static displayValue(val: Type.DateTimeSearch) {
            return Type.DATE_TIME_SEARCH.displayValue(val);
        }
    }

    export class NoDateFields extends Eql<null> {}

    export class DatasetIncludeDeduplicatedDocs extends Eql<ProcessingDataset> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(ProcessingDataset);
    }

    export class Dataset extends Eql<ProcessingDataset> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(ProcessingDataset);
    }

    export interface ProcessedArgs {
        jobId?: number;
        stage?: ProcessingDefs.Stage;
        success?: boolean;
    }

    export class Processed extends Eql<ProcessedArgs> {
        static override ARG_TYPE_STRUCTURE = {
            jobId: new Type.Optional(Type.NUMBER),
            stage: new Type.Optional(new Type.Object(ProcessingDefs.Stage)),
            success: new Type.Optional(Type.BOOLEAN),
        };
    }

    export class ProcessingStatus extends Eql<{ stage: ProcessingDefs.Stage; state: boolean }> {
        static override ARG_TYPE_STRUCTURE = {
            stage: new Type.Object(ProcessingDefs.Stage),
            state: Type.BOOLEAN,
        };
    }

    export class OriginalPath extends Eql<string> {
        static override ARG_TYPE_STRUCTURE = Type.TEXT;
        static override DISPLAY_NAME = "Native Path";
    }

    export class ProcessingFlags extends Eql<ProcessingDefs.Flag> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(ProcessingDefs.Flag);
    }

    export class ProducedFrom extends Eql<Production> {
        static override DISPLAY_NAME = "Produced From";
        static override ARG_TYPE_STRUCTURE = new Type.Optional(new Type.Object(Production));
    }

    export class ProducedIn extends Eql<Production> {
        static override DISPLAY_NAME = "Produced In";
        static override ARG_TYPE_STRUCTURE = new Type.Optional(new Type.Object(Production));
    }

    export class ModifiedBy extends Eql<ProductionJob> {
        static override DISPLAY_NAME = "Modified By";
        static override ARG_TYPE_STRUCTURE = new Type.Optional(new Type.Object(ProductionJob));
    }

    export class ModifiedIn extends Eql<ProductionJob> {
        static override DISPLAY_NAME = "Modified In";
        static override ARG_TYPE_STRUCTURE = new Type.Optional(new Type.Object(ProductionJob));
    }

    export class ProductionFlags extends Eql<{
        originalDocs: boolean;
        flag: Production.Flag;
        productionId: number;
    }> {
        static override ARG_TYPE_STRUCTURE = {
            originalDocs: Type.BOOLEAN,
            flag: new Type.Object(Production.Flag),
            productionId: new Type.Optional(Type.NUMBER),
        };
    }

    export class SearchResult extends Eql<{ searchId: number; ids: number[] }> {
        static override ARG_TYPE_STRUCTURE = {
            searchId: Type.NUMBER,
            ids: new Type.List(Type.NUMBER),
        };
    }

    export interface PriorSearchArgs {
        searchId: number;
        searchName: string;
    }

    export class PriorSearch extends Eql<PriorSearchArgs> {
        static override ARG_TYPE_STRUCTURE = {
            searchId: Type.NUMBER,
            searchName: Type.TEXT,
        };
        static override DISPLAY_NAME = "Prior Search";
    }

    interface PredictionRange {
        model: PredictionModel;
        range: Type.NumberRange;
    }

    export class ModelPrediction extends Eql<PredictionRange> {
        static override ARG_TYPE_STRUCTURE = {
            model: new Type.Object(PredictionModel),
            range: new Type.NumberType("NonNegativeNumber", 0, 100).searchType(),
        };
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_PREDICTION_MODELS,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
        static override displaySortArg(sortArg: any) {
            const model = Base.get(PredictionModel, Number(sortArg));
            return model ? "Model: " + model.name : null;
        }
    }

    export class HasModelPrediction extends Eql<PredictionModel> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(PredictionModel);
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_PREDICTION_MODELS,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
    }

    export class ModelConfidence extends Eql<PredictionRange> {
        static override ARG_TYPE_STRUCTURE = {
            model: new Type.Object(PredictionModel),
            range: new Type.NumberType("NonNegativeNumber", 0, 100).searchType(),
        };
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_PREDICTION_MODELS,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
    }

    export class ModelInput extends Eql<{ model: PredictionModel; high?: boolean }> {
        static override ARG_TYPE_STRUCTURE = {
            model: new Type.Object(PredictionModel),
            high: new Type.Optional(Type.BOOLEAN),
        };
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_PREDICTION_MODELS,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
    }

    export class ModelHoldout extends Eql<{
        model: PredictionModel;
        high?: boolean;
        subset?: string;
    }> {
        static override ARG_TYPE_STRUCTURE = {
            model: new Type.Object(PredictionModel),
            high: new Type.Optional(Type.BOOLEAN),
            subset: new Type.Optional(Type.TEXT),
        };
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_PREDICTION_MODELS,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
    }

    export class ModelUnreviewed extends Eql<PredictionModel> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(PredictionModel);
        static override isAccessible() {
            return User.me.can(
                Perm.RECEIVE_PREDICTION_MODELS,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
    }

    export class ClusteredDocument extends Eql<{ modelId: number; inclusion: ClusterInclusion }> {
        static override ARG_TYPE_STRUCTURE = {
            modelId: Type.NUMBER,
            inclusion: new Type.Object(ClusterInclusion),
        };
        static override DISPLAY_NAME = "Cluster Document";
    }

    export class ClusterMember extends Eql<number> {
        static override ARG_TYPE_STRUCTURE = Type.NUMBER;
        static override DISPLAY_NAME = "Cluster";
    }

    export class ClusterMemberNearestNeighbors extends Eql<{
        docId: number;
        modelId: number;
        k?: number;
        docDisplay?: string;
    }> {
        static override ARG_TYPE_STRUCTURE = {
            docId: Type.NUMBER,
            docDisplay: new Type.Optional(Type.TEXT),
            modelId: Type.NUMBER,
            k: new Type.Optional(Type.NUMBER),
        };
        static override DISPLAY_NAME = "Cluster Nearest Neighbors";
    }

    export interface ReferenceDocument {
        batesPrefix: string;
        batesNumber: _Bates.Number;
        projectId: Project;
    }
    module ReferenceDocument {
        export var ARG_TYPE_STRUCTURE = {
            batesPrefix: Type.TEXT,
            batesNumber: Type.BATES_NUMBER,
            projectId: new Type.Object(Project),
        };
    }

    export class Versions extends Eql<ReferenceDocument> {
        static override ARG_TYPE_STRUCTURE = ReferenceDocument.ARG_TYPE_STRUCTURE;
    }

    export class Units extends Eql<ReferenceDocument> {
        static override ARG_TYPE_STRUCTURE = ReferenceDocument.ARG_TYPE_STRUCTURE;
    }

    export class Attachments extends Eql<ReferenceDocument> {
        static override ARG_TYPE_STRUCTURE = ReferenceDocument.ARG_TYPE_STRUCTURE;
        static override DISPLAY_NAME = "Attachment Group";
    }

    export class FilePath extends Eql<{ path: string[]; directChildrenOnly?: boolean }> {
        static override ARG_TYPE_STRUCTURE = {
            path: new Type.List(Type.PATH_TEXT),
            directChildrenOnly: new Type.Optional(Type.BOOLEAN),
        };
        static override DISPLAY_NAME = "File Path";
    }

    export class Docs extends Eql<Document.Id[]> {
        static override ARG_TYPE_STRUCTURE = new Type.List(Type.NUMBER); // Document IDs
    }

    /**
     * Only the `ids` are used by search; `text` and `pageSearch` are kept so they can
     * by displayed by a MultiBatesTerm.
     */
    export class MultiBates extends Eql<{ ids: Document.Id[]; text: string; pageSearch: boolean }> {
        static override ARG_TYPE_STRUCTURE = {
            ids: new Type.List(Type.NUMBER),
            text: Type.TEXT,
            pageSearch: Type.BOOLEAN,
        };
    }

    export class Upload extends Eql<number> {
        static override ARG_TYPE_STRUCTURE = Type.NUMBER;

        /**
         * This override is done so that the `termName` of the property is set (from the perspective of
         * the backend), albeit in a hacky/indirect/fragile way. For example, this override makes
         * `Util.resultsURL( { eql: new Property.Upload(uploadId) })` work without setting the termName,
         * because the object is serialized with dojo's `objectToQuery`, which uses this object's
         * `toString` method, which in turn uses this `toJson` override.
         *
         * Most likely this override should be removed, and "processed" should just be passed into the
         * constructor as the `termName` as appropriate. It takes some work to change at this point
         * though, as it's a non-trivial effort to look over and understand all the usages of `toJson`.
         */
        override toJson() {
            return ["upload", this.args, "processed"];
        }

        override fromJson(id: number) {
            return id;
        }
    }

    export class DocType extends Eql<_DocType> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(_DocType);
        static override DISPLAY_NAME = "Type";
    }

    export class Language extends Eql<{ language: _Language; primaryLang?: boolean }> {
        static override ARG_TYPE_STRUCTURE = {
            language: new Type.Object(_Language, function (l) {
                return Project.CURRENT.expectedLangs.indexOf(l) > -1;
            }),
            primaryLang: new Type.Optional(Type.BOOLEAN),
        };
    }

    export class AllDocs extends Eql<null> {
        static override ARG_TYPE_STRUCTURE = Type.NULL;
    }

    export class HasFormat extends Eql<FormatType> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(FormatType);
    }

    export class HasContents extends Eql<null> {}

    export class HasViews extends Eql<null> {}

    export class HasNotes extends Eql<null> {
        static override ARG_TYPE_STRUCTURE = Type.NULL;
        static override isAccessible() {
            return NoteUtil.canReadNotes();
        }
        override matches(doc: Document, mutator: DocumentMutator) {
            return doc.getNotes().some((n) => n.hasText());
        }
    }

    export class HasHighlight extends Eql<null> {
        static override isAccessible() {
            return NoteUtil.canReadNotes();
        }
        override matches(doc: Document, mutator: DocumentMutator) {
            return doc.getHighlights().length > 0;
        }
    }

    export class HasPromotions extends Eql<null> {
        static override ARG_TYPE_STRUCTURE = Type.NULL;
        static override isAccessible() {
            return Eca.inContext();
        }
    }

    export class HasCodes extends Eql<null> {
        static override ARG_TYPE_STRUCTURE = Type.NULL;
        static override isAccessible() {
            return _Code.canReadSome(User.Override.ELEVATED_OR_ORGADMIN);
        }
        override matches(doc: Document, mutator: DocumentMutator) {
            return (
                Object.values(mutator.codes).some((c) => c)
                || doc.codes.some((c) => !(c in mutator.codes))
            );
        }
    }

    export class HasFreeformCodes extends Eql<null> {
        static override ARG_TYPE_STRUCTURE = Type.NULL;
        static override isAccessible() {
            return Freeform.canReadSomeCodes(User.Override.ELEVATED_OR_ORGADMIN);
        }
    }

    export class HasTags extends Eql<null> {
        static override ARG_TYPE_STRUCTURE = Type.NULL;
    }

    class WithDateRange extends Eql<Type.DateTimeSearch> {
        constructor(args: Type.DateTimeSearch, termName?: string, argsAreRaw?: boolean) {
            super(args, termName, argsAreRaw);
        }
        static override ARG_TYPE_STRUCTURE = new Type.Optional(Type.DATE_TIME_SEARCH);
    }

    export class HasUpload extends WithDateRange {}

    export class HasProcessedUpload extends WithDateRange {}

    export class HasDataset extends WithDateRange {}

    export type DocSet = Dataset | Upload | ProducedFrom | HasProcessedUpload | HasDataset;

    export class DocumentSet extends Eql<DocSet> {
        static wrappedProperties = [Dataset, Upload, ProducedFrom, HasProcessedUpload, HasDataset];
        static override DISPLAY_NAME = "Document Set";
        static override ARG_TYPE_STRUCTURE = {
            dataset: new Type.Optional(new Type.Object(ProcessingDataset)),
            upload: new Type.Optional(Type.NUMBER),
            producedFrom: new Type.Optional(new Type.Object(Production)),
        };
        display() {
            const p = this.args;
            if (p instanceof Property.Upload) {
                return Base.get(_Upload.Homepage, Number(p.args)).displayWithType();
            }
            if (p instanceof Property.Dataset) {
                return p.args.displayWithType();
            }
            if (p instanceof Property.ProducedFrom) {
                return p.args ? p.args.displayWithType() : "(Any Production)";
            }
            if (p instanceof Property.HasDataset) {
                return "(Any Native Upload)";
            }
            if (p instanceof Property.HasProcessedUpload) {
                return "(Any Processed Upload)";
            }
        }
        displayProperty() {
            const p = this.args;
            if (
                p instanceof Property.Upload
                || p instanceof Property.Dataset
                || (p instanceof Property.ProducedFrom && p.args !== null)
            ) {
                return "Document Set";
            }
            if (
                p instanceof Property.HasDataset
                || p instanceof Property.HasProcessedUpload
                || (p instanceof Property.ProducedFrom && p.args === null)
            ) {
                return "Document Set Type";
            }
        }
        unwrap() {
            return this.args;
        }
    }

    export class HasRating extends Eql<null> {
        static override ARG_TYPE_STRUCTURE = Type.NULL;
        static override isAccessible() {
            return User.me.can(
                Perm.READ_RATING,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
        override matches(doc: Document, mutator: DocumentMutator) {
            var curr = mutator.rating || doc.rating;
            return !curr.equals(_Rating.Unrated);
        }
    }

    export class IsAutoCoded extends Eql<null> {
        static override isAccessible() {
            return User.me.can(Perm.ADMIN, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);
        }
        // unable to use Document/DocumentMutator to determine current auto-coded state
    }

    export class HasImageRedaction extends Eql<null> {
        static override isAccessible() {
            return Redaction.canReadRedactions();
        }
        override matches(doc: Document, mutator: DocumentMutator) {
            return doc.getImageRedactions().length > 0;
        }
    }

    export class HasFpiRedaction extends Eql<null> {
        static override isAccessible() {
            return Redaction.canReadRedactions();
        }
        override matches(doc: Document, mutator: DocumentMutator) {
            return doc.getFpiRedactions().length > 0;
        }
    }

    export class HasMediaRedaction extends Eql<null> {
        static override isAccessible(): boolean {
            return Redaction.canReadRedactions();
        }

        override matches(doc: Document, mutator: DocumentMutator): boolean {
            return doc.getMediaRedactions().length > 0;
        }
    }

    export class HasSpreadsheetRedaction extends Eql<null> {
        static override isAccessible() {
            return Redaction.canReadRedactions();
        }
        override matches(doc: Document, mutator: DocumentMutator) {
            return doc.getSpreadsheetRedactions().length > 0;
        }
    }

    export class HasFsiSpreadsheetRedaction extends Eql<null> {
        static override isAccessible(): boolean {
            return Redaction.canReadRedactions();
        }

        override matches(doc: Document, mutator: DocumentMutator): boolean {
            return doc.getFsiSpreadsheetRedactions().length > 0;
        }
    }

    export class HasMetadataRedactions extends Eql<number> {
        static override isAccessible() {
            return Redaction.canReadRedactions();
        }
        static override ARG_TYPE_STRUCTURE = Type.NUMBER;
        override fromJson(id: number) {
            return id || 0;
        }
        override matches(doc: Document, mutator: DocumentMutator) {
            if (!doc.getMetadataRedactions().length) {
                return false;
            }
            if (!this.args) {
                return true;
            }
            return doc.getMetadataRedactions().some((r) => r.fieldId === this.args);
        }
    }

    export interface RatingConflictArgs {
        type?: RatingConflictType;
        userId?: User;
        rating?: _Rating;
        start?: Type.DateTimeSearch;
    }
    export class RatingConflict extends Eql<RatingConflictArgs> {
        static override isAccessible() {
            return User.me.can(Perm.ANALYTICS, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);
        }
        static override ARG_TYPE_STRUCTURE = {
            type: new Type.Optional(new Type.Object(RatingConflictType)),
            userId: new Type.Optional(new Type.Object(User)),
            rating: new Type.Optional(new Type.Object(_Rating)),
            start: new Type.Optional(Type.DATE_TIME_SEARCH),
        };
        static override displaySortArg(sortArg: any) {
            return _RatingConflict.displayNames[sortArg];
        }
    }

    export class UserRated extends Eql<User> {
        static override isAccessible() {
            return User.me.can(Perm.ANALYTICS, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);
        }
        static override ARG_TYPE_STRUCTURE = new Type.Object(User);
    }

    export class UserAndAdminRated extends Eql<User> {
        static override isAccessible() {
            return User.me.can(Perm.ANALYTICS, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);
        }
        static override ARG_TYPE_STRUCTURE = new Type.Object(User);
    }

    export class UserRatingErrors extends Eql<User> {
        static override isAccessible() {
            return User.me.can(Perm.ANALYTICS, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);
        }
        static override ARG_TYPE_STRUCTURE = new Type.Object(User);
    }

    export class NeedsAdminReview extends Eql<User> {
        static override isAccessible() {
            return User.me.can(Perm.ANALYTICS, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);
        }
        static override ARG_TYPE_STRUCTURE = new Type.Object(User);
    }

    export interface SearchTermReportArgs {
        searchTermReportId: number;
        searchTermReportContent: _SearchTermReport.SearchTermReportContent;
    }

    export class SearchTermReport extends Eql<SearchTermReportArgs> {
        static override isAccessible() {
            return (
                Base.get(_SearchTermReport).length > 0
                || User.me.can(
                    Perm.RECEIVE_STRS,
                    Project.CURRENT,
                    User.Override.ELEVATED_OR_ORGADMIN,
                )
            );
        }
        static override ARG_TYPE_STRUCTURE = {
            searchTermReportId: new Type.Optional(Type.NUMBER),
            searchTermReportContent: new Type.Object(_SearchTermReport.SearchTermReportContent),
        };
    }

    export interface MetadataArgs {
        field: _Metadata.Field;
        fieldName?: string; // Specified when searching for canonical field
        value: any;
    }

    export class Metadata extends Eql<MetadataArgs> {
        static override ARG_TYPE_STRUCTURE = new Type.Polymorphic({
            field: new Type.Object(_Metadata.Field),
            fieldName: new Type.Optional(Type.TEXT),
            value: new Type.PolymorphicOn("field", _Metadata.Field, (mf) => mf.type.searchType()),
        });
        static override displaySortArg(sortArg: any) {
            const fieldId: number = +sortArg;
            const fields = _Metadata.primaryFields();
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].id === fieldId) {
                    return fields[i].name;
                }
            }
        }
    }

    export class ExactMetadata extends Eql<MetadataArgs> {
        // Unlike all other properties, this one operates on normal values, not search values.
        static override ARG_TYPE_STRUCTURE = new Type.Polymorphic({
            field: new Type.Object(_Metadata.Field),
            fieldName: new Type.Optional(Type.TEXT),
            value: new Type.PolymorphicOn("field", _Metadata.Field, (mf) => mf.type),
        });
    }

    export interface FreeformCodeArgs {
        code: Freeform.Code;
        value?: any;
    }

    export class FreeformCode extends Eql<FreeformCodeArgs> {
        static override isAccessible() {
            return Freeform.canReadSomeCodes();
        }
        static override ARG_TYPE_STRUCTURE = new Type.Polymorphic({
            code: new Type.Object(Freeform.Code),
            value: new Type.PolymorphicOn("code", Freeform.Code, (mf) => mf.type.searchType()),
        });
    }

    export class ExactFreeformCode extends Eql<FreeformCodeArgs> {
        static override isAccessible() {
            return Freeform.canReadSomeCodes();
        }
        // Unlike all other properties, this one operates on normal values, not search values.
        static override ARG_TYPE_STRUCTURE = new Type.Polymorphic({
            code: new Type.Object(Freeform.Code),
            value: new Type.PolymorphicOn("code", Freeform.Code, (code) => code.type),
        });
    }

    export class HasFreeformCode extends Eql<Freeform.Code> {
        static override ARG_TYPE_STRUCTURE = new Type.Object(Freeform.Code);
        static override isAccessible() {
            return Freeform.canReadSomeCodes();
        }
        override matches(doc: Document, mutator: DocumentMutator) {
            const codeId = this.args.id;
            return codeId in mutator.freeformCode
                ? !!mutator.freeformCode[codeId]
                : !!doc.getFreeformCodeValue(codeId);
        }
    }

    export interface BatesArgs {
        prefix?: string;
        range?: _Bates.NumberRange;
        pageSearch?: boolean;
    }

    export class Bates extends Eql<BatesArgs> {
        static override ARG_TYPE_STRUCTURE = {
            prefix: new Type.Optional(Type.TEXT),
            range: new Type.Optional(Type.BATES_NUMBER_RANGE),
            pageSearch: new Type.Optional(Type.BOOLEAN),
        };
    }

    export class Pages extends Eql<Type.NumberRange> {
        static override ARG_TYPE_STRUCTURE = Type.NON_NEGATIVE_NUMBER.searchType();
        static override DISPLAY_NAME = "Num Pages";
    }

    export class Rating extends Eql<_Rating> {
        static override isAccessible() {
            return User.me.can(
                Perm.READ_RATING,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
        static override ARG_TYPE_STRUCTURE = new Type.Object(_Rating);
        override matches(doc: Document, mutator: DocumentMutator) {
            var curr = mutator.rating || doc.rating;
            return curr === this.args;
        }
    }

    export class NoteSearch extends Eql<{
        contents: string;
        relativeDate: Type.RelativeDate;
        range: Type.DateTimeSearch;
        author: User;
        authorGroup: User.Group;
    }> {
        static override isAccessible() {
            return NoteUtil.canReadNotes();
        }
        static override ARG_TYPE_STRUCTURE = {
            contents: new Type.Optional(Type.TEXT),
            range: new Type.Optional(Type.DATE_TIME_SEARCH),
            relativeDate: new Type.Optional(Type.RELATIVE_DATE),
            author: new Type.Optional(new Type.Object(User)),
            authorGroup: new Type.Optional(new Type.Object(User.Group)),
        };
    }

    export class RedactionSearchInclusionLevel extends Base.Primitive<string> {
        static readonly IncludeMetadata = new RedactionSearchInclusionLevel(
            "IncludeMetadata",
            "Include metadata redactions",
            0,
        );
        static readonly ExcludeMetadata = new RedactionSearchInclusionLevel(
            "ExcludeMetadata",
            "Exclude metadata redactions",
            1,
        );
        static readonly MetadataOnly = new RedactionSearchInclusionLevel(
            "MetadataOnly",
            "Metadata redactions only",
            2,
        );
        static getSearchInclusionLevel(id: String): RedactionSearchInclusionLevel {
            switch (id) {
                case RedactionSearchInclusionLevel.IncludeMetadata.getId():
                    return RedactionSearchInclusionLevel.IncludeMetadata;
                case RedactionSearchInclusionLevel.ExcludeMetadata.getId():
                    return RedactionSearchInclusionLevel.ExcludeMetadata;
                case RedactionSearchInclusionLevel.MetadataOnly.getId():
                    return RedactionSearchInclusionLevel.MetadataOnly;
                default:
                    return RedactionSearchInclusionLevel.IncludeMetadata;
            }
        }
        override get className() {
            return "RedactionSearchInclusionLevel";
        }

        private constructor(
            id: string,
            public override name: string,
            public ordering: number,
        ) {
            super(id, name);
        }

        public override display() {
            return this.name;
        }

        public getId() {
            return this.id;
        }

        public static allRedactionSearchInclusionLevels(): RedactionSearchInclusionLevel[] {
            return [
                RedactionSearchInclusionLevel.IncludeMetadata,
                RedactionSearchInclusionLevel.ExcludeMetadata,
                RedactionSearchInclusionLevel.MetadataOnly,
            ];
        }
    }

    export class OverviewTopicSearch extends Eql<{
        title: string;
        sentiments: string[];
    }> {
        static override ARG_TYPE_STRUCTURE = {
            title: new Type.Optional(Type.TEXT),
            sentiments: new Type.Optional(new Type.List(Type.TEXT)),
        };
    }

    export class OverviewDescriptionSearch extends Eql<null> {}

    export class HighlightSearch extends Eql<{
        relativeDate: Type.RelativeDate;
        range: Type.DateTimeSearch;
        author: User;
        authorGroup: User.Group;
        color: string;
        highlightNoteContents: string;
    }> {
        static override isAccessible() {
            return NoteUtil.canReadNotes();
        }
        static override ARG_TYPE_STRUCTURE = {
            range: new Type.Optional(Type.DATE_TIME_SEARCH),
            relativeDate: new Type.Optional(Type.RELATIVE_DATE),
            author: new Type.Optional(new Type.Object(User)),
            authorGroup: new Type.Optional(new Type.Object(User.Group)),
            color: new Type.Optional(Type.TEXT),
            highlightNoteContents: new Type.Optional(Type.TEXT),
        };
    }

    export class RedactionSearch extends Eql<{
        relativeDate: Type.RelativeDate;
        range: Type.DateTimeSearch;
        author: User;
        authorGroup: User.Group;
        redactionStamp: string;
        inclusion: string;
    }> {
        static override isAccessible() {
            return NoteUtil.canReadNotes();
        }
        static override ARG_TYPE_STRUCTURE = {
            range: new Type.Optional(Type.DATE_TIME_SEARCH),
            relativeDate: new Type.Optional(Type.RELATIVE_DATE),
            author: new Type.Optional(new Type.Object(User)),
            authorGroup: new Type.Optional(new Type.Object(User.Group)),
            redactionStamp: new Type.Optional(Type.TEXT),
            inclusion: new Type.Optional(Type.TEXT),
        };
    }

    export interface NoteDateArgs {
        range?: Type.DateTimeSearch;
        relativeDate?: Type.RelativeDate;
    }

    export class NoteCreated extends Eql<NoteDateArgs> {
        static override isAccessible() {
            return NoteUtil.canReadNotes();
        }
        static override ARG_TYPE_STRUCTURE = {
            range: new Type.Optional(Type.DATE_TIME_SEARCH),
            relativeDate: new Type.Optional(Type.RELATIVE_DATE),
        };
    }

    export class NoteUpdated extends Eql<NoteDateArgs> {
        static override isAccessible() {
            return NoteUtil.canReadNotes();
        }
        static override ARG_TYPE_STRUCTURE = {
            range: new Type.Optional(Type.DATE_TIME_SEARCH),
            relativeDate: new Type.Optional(Type.RELATIVE_DATE),
        };
    }

    export class NoteAuthor extends Eql<User> {
        static override isAccessible() {
            return NoteUtil.canReadNotes();
        }
        static override ARG_TYPE_STRUCTURE = new Type.Object(User);
    }

    export class NoteContents extends Eql<string> {
        static override isAccessible() {
            return NoteUtil.canReadNotes();
        }
        static override ARG_TYPE_STRUCTURE = Type.TEXT;
    }

    export class HighlightColor extends Eql<string> {
        static override isAccessible() {
            return NoteUtil.canReadNotes();
        }
        static override ARG_TYPE_STRUCTURE = Type.TEXT;
        override matches(doc: Document, mutator: DocumentMutator) {
            return doc.getHighlights().some((h) => {
                return h.revRect.parent.getColor() == this.args;
            });
        }
    }

    export class HasRedactions extends Eql<string> {
        static override isAccessible() {
            return Redaction.canReadRedactions();
        }
        static override ARG_TYPE_STRUCTURE = Type.NULL;
        override matches(doc: Document, mutator: DocumentMutator): boolean {
            return (
                doc.getImageRedactions().length > 0
                || doc.getFpiRedactions().length > 0
                || doc.getSpreadsheetRedactions().length > 0
                || doc.getFsiSpreadsheetRedactions().length > 0
                || doc.getMetadataRedactions().length > 0
                || doc.getMediaRedactions().length > 0
            );
        }
    }

    export class RedactionAuthor extends Eql<User> {
        static override isAccessible() {
            return Redaction.canReadRedactions();
        }
        static override ARG_TYPE_STRUCTURE = new Type.Object(User);
    }

    export class RedactionContents extends Eql<string> {
        static override isAccessible() {
            return Redaction.canReadRedactions();
        }
        static override ARG_TYPE_STRUCTURE = Type.TEXT;
    }

    export class RedactionMetadataField extends Eql<_Metadata.Field> {
        static override isAccessible() {
            return Redaction.canReadRedactions();
        }
        static override ARG_TYPE_STRUCTURE = new Type.Object(_Metadata.Field);
    }

    export class RedactionStampProperty extends Eql<string> {
        static override isAccessible() {
            return Redaction.canReadRedactions();
        }
        static override ARG_TYPE_STRUCTURE = Type.TEXT;
    }

    export interface EventArgs {
        userId?: User;
        groupId?: User.Group;
        dateRange?: Type.DateTimeSearch;
        relativeDate?: Type.RelativeDate;
    }

    export interface BinderEventArgs extends EventArgs {
        labelId?: Binder;
    }
    export class tagEvent extends Eql<BinderEventArgs> {
        static override ARG_TYPE_STRUCTURE = {
            labelId: new Type.Optional(new Type.Object(Binder)),
            userId: new Type.Optional(new Type.Object(User)),
            groupId: new Type.Optional(new Type.Object(User.Group)),
            dateRange: new Type.Optional(Type.DATE_TIME_SEARCH),
            relativeDate: new Type.Optional(Type.RELATIVE_DATE),
        };
    }

    export interface AutoCodedEventArgs extends EventArgs {
        ruleId?: AutoCodeRule;
    }
    export class AutoCodedEvent extends Eql<AutoCodedEventArgs> {
        static override ARG_TYPE_STRUCTURE = {
            ruleId: new Type.Optional(new Type.Object(AutoCodeRule)),
            userId: new Type.Optional(new Type.Object(User)),
            groupId: new Type.Optional(new Type.Object(User.Group)),
            dateRange: new Type.Optional(Type.DATE_TIME_SEARCH),
            relativeDate: new Type.Optional(Type.RELATIVE_DATE),
        };
        static override isAccessible() {
            return User.me.can(Perm.ADMIN, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);
        }
    }

    export interface PromotionEventArgs extends EventArgs {
        reason?: PromotionReason;
    }
    export class PromotionEvent extends Eql<PromotionEventArgs> {
        static override ARG_TYPE_STRUCTURE = {
            reason: new Type.Optional(new Type.Object(PromotionReason)),
            userId: new Type.Optional(new Type.Object(User)),
            groupId: new Type.Optional(new Type.Object(User.Group)),
            dateRange: new Type.Optional(Type.DATE_TIME_SEARCH),
            relativeDate: new Type.Optional(Type.RELATIVE_DATE),
        };
        static override isAccessible() {
            return Eca.inContext();
        }
    }

    export interface CodeEventArgs extends EventArgs {
        labelId?: _Code | Category;
    }
    export class CodeEvent extends Eql<CodeEventArgs> {
        static override isAccessible() {
            return _Code.canReadSome(User.Override.ELEVATED_OR_ORGADMIN);
        }
        static override ARG_TYPE_STRUCTURE = {
            // We don't restrict the id to just Codes, since we can also search for Categories with the
            // same event. Therefore, we create an anonymous subclass of Type that allows for either
            // Codes or Categories. This is fine, because codes and categories will never share ids, so
            // it is unambiguous what we are referring to with the id. If we go to fetch a code with a
            // given id, and it is undefined, we know it is either invalid or a category.
            labelId: new Type.Optional(
                new (class extends Type.Type {
                    constructor(
                        public code = new Type.Object(_Code),
                        public category = new Type.Object(Category),
                    ) {
                        super("CodeOrCategory");
                    }
                    override toJsonValue(v: any) {
                        return this.code.toJsonValue(v); // This will return the id regardless of if it's actually a code
                    }
                    override fromJsonValue(v: any) {
                        return this.code.fromJsonValue(v) || this.category.fromJsonValue(v);
                    }
                    override displayValue(v: any) {
                        return this.code.displayValue(v); // Again, this will return the display name regardless
                    }
                })(),
            ),
            userId: new Type.Optional(new Type.Object(User)),
            groupId: new Type.Optional(new Type.Object(User.Group)),
            dateRange: new Type.Optional(Type.DATE_TIME_SEARCH),
            relativeDate: new Type.Optional(Type.RELATIVE_DATE),
        };
    }

    export interface FreeformCodeEventArgs extends EventArgs {
        labelId?: Freeform.Code;
    }

    export class FreeformCodeEvent extends Eql<FreeformCodeEventArgs> {
        static override isAccessible() {
            return Freeform.canReadSomeCodes(User.Override.ELEVATED_OR_ORGADMIN);
        }
        static override ARG_TYPE_STRUCTURE = {
            // We don't restrict the id to just Codes, since we can also search for Categories with the
            // same event. Therefore, we create an anonymous subclass of Type that allows for either
            // Codes or Categories. This is fine, because codes and categories will never share ids, so
            // it is unambiguous what we are referring to with the id. If we go to fetch a code with a
            // given id, and it is undefined, we know it is either invalid or a category.
            labelId: new Type.Optional(
                new (class extends Type.Type {
                    constructor(public code = new Type.Object(Freeform.Code)) {
                        super("FreeformCode");
                    }
                    override toJsonValue(v: any) {
                        return this.code.toJsonValue(v); // This will return the id regardless of if it's actually a code
                    }
                    override fromJsonValue(v: any) {
                        return this.code.fromJsonValue(v);
                    }
                    override displayValue(v: any) {
                        return this.code.displayValue(v); // Again, this will return the display name regardless
                    }
                })(),
            ),
            userId: new Type.Optional(new Type.Object(User)),
            groupId: new Type.Optional(new Type.Object(User.Group)),
            dateRange: new Type.Optional(Type.DATE_TIME_SEARCH),
            relativeDate: new Type.Optional(Type.RELATIVE_DATE),
        };
    }

    export class BatchUUIDEvent extends Eql<{
        batchUUID: string;
        error: boolean;
        summary: string;
    }> {
        static override DISPLAY_NAME = "Batch Action";
        static override isAccessible() {
            return true;
        }
        static override ARG_TYPE_STRUCTURE = {
            batchUUID: Type.TEXT,
            error: Type.BOOLEAN,
            // Batch Summary, does not include the fact if error is true or false
            summary: Type.TEXT,
        };
        static getEql(uuid: string, success: boolean, summary: string) {
            return new Property.BatchUUIDEvent(
                {
                    batchUUID: uuid,
                    error: !success,
                    summary: summary,
                },
                "batchUUIDEvent",
            );
        }
    }

    export interface RateEventArgs extends EventArgs {
        rating?: _Rating;
    }
    export class RateEvent extends Eql<RateEventArgs> {
        static override isAccessible() {
            return User.me.can(
                Perm.READ_RATING,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            );
        }
        static override ARG_TYPE_STRUCTURE = {
            rating: new Type.Optional(new Type.Object(_Rating)),
            userId: new Type.Optional(new Type.Object(User)),
            groupId: new Type.Optional(new Type.Object(User.Group)),
            dateRange: new Type.Optional(Type.DATE_TIME_SEARCH),
            relativeDate: new Type.Optional(Type.RELATIVE_DATE),
        };
    }

    export class ViewEvent extends Eql<EventArgs> {
        static override ARG_TYPE_STRUCTURE = {
            userId: new Type.Optional(new Type.Object(User)),
            dateRange: new Type.Optional(Type.DATE_TIME_SEARCH),
            groupId: new Type.Optional(new Type.Object(User.Group)),
            relativeDate: new Type.Optional(Type.RELATIVE_DATE),
        };
    }

    export class ChatConversation extends Eql<ReferenceDocument> {
        static override ARG_TYPE_STRUCTURE = ReferenceDocument.ARG_TYPE_STRUCTURE;
    }

    export class EmailThread extends Eql<ReferenceDocument> {
        static override ARG_TYPE_STRUCTURE = ReferenceDocument.ARG_TYPE_STRUCTURE;
    }

    export class ExactDuplicate extends Eql<ReferenceDocument> {
        static override ARG_TYPE_STRUCTURE = ReferenceDocument.ARG_TYPE_STRUCTURE;
    }

    export class NearDuplicateGroup extends Eql<ReferenceDocument> {
        static override ARG_TYPE_STRUCTURE = ReferenceDocument.ARG_TYPE_STRUCTURE;
    }

    export class AllReferences extends Eql<ReferenceDocument> {
        static override ARG_TYPE_STRUCTURE = ReferenceDocument.ARG_TYPE_STRUCTURE;
    }

    interface SearchTermReportUniquesArgs {
        searchTermReport: _SearchTermReport;
        searchId: number;
        searchContent?: string;
    }

    export class SearchTermReportUniques extends Eql<SearchTermReportUniquesArgs> {
        static override ARG_TYPE_STRUCTURE = {
            searchTermReport: new Type.Object(_SearchTermReport),
            searchId: Type.NUMBER,
            searchContent: new Type.Optional(Type.TEXT),
        };
    }

    export class Size extends Eql<Type.NumberRange> {
        static override ARG_TYPE_STRUCTURE = Type.NON_NEGATIVE_NUMBER.searchType();
        static override DISPLAY_NAME = "Billable Size";
    }

    export class TotalSize extends Eql<Type.NumberRange> {
        static override ARG_TYPE_STRUCTURE = Type.NON_NEGATIVE_NUMBER.searchType();
        static override DISPLAY_NAME = "Total Size";
    }

    export class AttachmentGroupSize extends Eql<Type.NumberRange> {
        static override ARG_TYPE_STRUCTURE = Type.NON_NEGATIVE_NUMBER.searchType();
        static override DISPLAY_NAME = "Attachment Group Size";
    }

    // Mapping of eqlNames from the backend to the corresponding property.
    const all: { [eqlName: string]: typeof Eql } = {};

    /**
     * Iterate over all exported members in this module, and for any classes extending Eql set the
     * EQL_NAME (and record the property in `all`).
     */
    function setEqlNameAndRecordProperties() {
        Object.entries(Property).forEach(([name, property]) => {
            const prop = property as any;
            const isProperty = prop?.prototype instanceof Eql;
            if (!isProperty) {
                return;
            }
            if (!prop.EQL_NAME) {
                // Default to the class name as the EQL_NAME. Convert from UpperCamelCase to
                // lowerCamelCase, since historically the Property's were all lowercase (so this is
                // necessary for backwards compatibility).
                prop.EQL_NAME = Str.uncapitalize(name);
            }
            name = prop.EQL_NAME;
            if (all[name]) {
                throw new Error(`Duplicate property eqlNames found: ${name}`);
            }
            all[name] = prop;
        });
    }
    setEqlNameAndRecordProperties();

    export function jsonToEql(value: any): Eql.Any {
        try {
            const prop = all[value[0]];
            if (Project.CURRENT && !prop.isAccessible()) {
                return new Property.Invalid(value, "noPermission");
            }
            return new prop(value[1], value[2], true);
        } catch (err) {
            if (value[2]) {
                /* TODO: Improve error handling. Usually if we can't parse a property,
                 * it's because it references work product (assignment groups,
                 * datasets, etc.) that has been deleted. But it may fail for other
                 * reasons.
                 */
                // If parsing a term-level property fails, replace it with the dummy "invalid" property.
                return new Property.Invalid(value, "invalid");
            }
            // Property is internal to a term, so propagate the exception up
            throw err;
        }
    }

    export function eqlNameToProp(name: string): typeof Eql {
        if (!(name in all)) {
            throw Error(`eql name ${name} doesn't exist`);
        }
        return all[name];
    }
}
export = Property;
