import { objectToQuery, ObjectToQueryMap } from "Everlaw/Core/Obj";
import * as Eql from "Everlaw/Eql";
import * as Rest from "Everlaw/Rest";

/*
These utility functions live outside Util.ts because their Eql and Rest imports won't work with
TS strict null checks for a while. Once those dependencies are fixed, these functions can be moved
back.
 */

/** See SearchPageController#searchHandler() */
export interface ResultsUrlParams<T = never> {
    eql?: string | Eql<T>;
    sort?: string;
    /** UserObject ID */
    uoId?: number;
    /** UserObject type */
    uoType?: string;
}

export function resultsURL<T>(
    reqObj?: ResultsUrlParams<T>,
    hashObj?: any,
    projectId?: number,
): string {
    let url = "search.do";
    if (projectId) {
        url = `/${projectId}/${url}`;
    }
    if (reqObj) {
        url += "?" + objectToQuery({ ...reqObj });
    }
    if (hashObj) {
        url += "#" + objectToQuery(hashObj);
    }
    return url;
}

/**
 * Used to handle GET requests that exceed a certain length; use this when the eql has too many
 * parameters. GET requests have a limited length, so this uses a POST request instead.
 * By default, searches that are created this way will appear on the homepage, even if the user
 * does not navigate to the URL. Set reviewed = false to hide the searches from the homepage until
 * the user visits the URL.
 */
export function resultsURLPlus(eql: string, reviewed?: boolean): Promise<string> {
    return new Promise<string>((resolve) => {
        Rest.post("search/getSearchForEql.rest", { eql, reviewed }).then(
            (search: { id: number }) => {
                resolve(`search.do#id=${search.id}`);
            },
        );
    });
}
