import Base = require("Everlaw/Base");
import Dom = require("Everlaw/Dom");
import { Note, NoteUtil } from "Everlaw/Note";
import Icon = require("Everlaw/UI/Icon");
import Is = require("Everlaw/Core/Is");
import RedactionStamp = require("Everlaw/Review/RedactionStamp");
import Rest = require("Everlaw/Rest");
import User = require("Everlaw/User");
import Perm = require("Everlaw/PermissionStrings");
import Project = require("Everlaw/Project");
import { Id } from "Everlaw/Document";

export abstract class Redaction extends Base.SecuredObject implements RedactionStamp.Stampable {
    abstract get redactionType(): NoteUtil.ParentType;
    redactionStamp: RedactionStamp;
    docId: Id;
    user: User = null;
    created: number = null;
    updated: number = null;
    updaterId: User.Id = null;
    text: string = null;
    userId: User.Id = null;
    notes: Note[] = [];

    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        if (Is.defined(params.notes)) {
            let paramNotes: any[] = params.notes;
            for (let i in paramNotes) {
                if (!(paramNotes[i] instanceof Note)) {
                    paramNotes[i] = new Note(paramNotes[i]);
                }
            }
        }
        if (params.userId) {
            this.userId = params.userId;
            this.user = Base.get(User, this.userId);
        }
        delete params.userId;
        if (params.redactionStampId) {
            this.redactionStamp = Base.get(RedactionStamp, params.redactionStampId);
            // This handles a rare case where users could have their review window open while stamps
            // get cleaned up, making it possible for users to apply stamps marked as not inUse to
            // redactions. If a redaction has a stamp id that we can't find in the frontend, we
            // should mark it as inUse and fetch it.
            if (!this.redactionStamp) {
                this.fetchAndMarkStamp(params.redactionStampId).then((stamp: RedactionStamp) => {
                    this.redactionStamp = stamp;
                    Base.publish(this);
                });
            }
        } else if (params.redactionStamp) {
            this.redactionStamp = params.redactionStamp;
        } else {
            this.redactionStamp = RedactionStamp.noStamp;
        }
        delete params.redactionStamp;

        Object.assign(this, params);
    }

    // This function is used to determine what the size of a stamp would be on this redaction.
    // Redaction types that don't override this will return a null size, indicating that the stamp
    // will be hidden when applied to a redaction (ex. MetadataRedaction).
    //
    // Types that do display the stamp should override this to return the appropriate size, such as
    // an arbitrary truthy value if the size doesn't matter (ex. MediaRedaction, SpreadsheetRedaction)
    // or a dynamic size if the redaction is resizeable (ex. SvgImageAnnotation).
    getStampSize(stamp: RedactionStamp): number {
        return null;
    }

    // Add the given note to the redaction
    addNote(n: Note): void {
        n.parentType = this.redactionType;
        n.parentId = this.id;
        this.notes.push(n);
    }

    // Remove the note from the redaction, returning whether the note was in this.notes
    removeNote(n: Note): boolean {
        const prevLength = this.notes.length;
        this.notes = this.notes.filter((e) => e.id !== n.id);
        return this.notes.length !== prevLength;
    }

    // Update redaction edited information with given user and current time.
    updateEditor(updater: User.Id) {
        this.updaterId = updater;
        this.updated = Date.now();
    }

    committed() {
        return Is.number(this.id);
    }

    abstract commit(): Promise<unknown>;

    updateStamp(redactionStamp: RedactionStamp) {
        this.redactionStamp = redactionStamp;
        this.commit().then((data) => {
            this._mixin(data);
            Base.publish(this);
        });
    }

    private async fetchAndMarkStamp(stampId: number): Promise<RedactionStamp> {
        const stamp = await Rest.get("fetchAndMarkStamp.rest", {
            stampId,
        });
        return Base.set(RedactionStamp, stamp);
    }

    stringifiableObject(): Record<string, unknown> {
        const json = {};
        for (let obj in this) {
            json[obj.toString()] = this[obj];
        }
        // A redaction having noStamp in the frontend is equivalent to a null stamp in the backend.
        if (json["redactionStamp"] === RedactionStamp.noStamp) {
            json["redactionStamp"] = null;
        }
        return json;
    }
}

export function createRedactionStampPermissionWarning() {
    const warningWrapper = Dom.create("div", {
        class: "redaction-warning",
    });
    new Icon("alert-triangle-20", {
        parent: warningWrapper,
    });
    Dom.create(
        "div",
        {
            class: "redaction-warning__text",
            content: "You don't have permission to edit or add a stamp to this redaction",
        },
        warningWrapper,
    );
    return warningWrapper;
}

export function canReadRedactions(): boolean {
    return User.me.can(Perm.READ_REDACTIONS, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);
}

export function canCreateRedactions(): boolean {
    return User.me.can(Perm.CREATE_REDACTIONS, Project.CURRENT, User.Override.ELEVATED);
}

export function canDeleteAllRedactions() {
    return User.me.can(Perm.ADMIN_REDACTIONS, Project.CURRENT, User.Override.ELEVATED);
}

export function canModifyOrDeleteRedaction(redaction: Redaction) {
    return (
        canDeleteAllRedactions()
        || (redaction.user === User.me
            && User.me.can(Perm.CREATE_REDACTIONS, Project.CURRENT, User.Override.ELEVATED))
    );
}
