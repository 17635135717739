import { Link } from "design-system";
import { checkPermissions } from "Everlaw/HeaderPermissionsUtil";
import { Recommendation } from "Everlaw/SmartOnboarding/Recommendation";
import { RecommendationLevel } from "Everlaw/SmartOnboarding/RecommendationLevel";
import { RecommendationNavigationPage } from "Everlaw/SmartOnboarding/RecommendationNavigationPage";
import { RecommendationPreferencePage } from "Everlaw/SmartOnboarding/RecommendationPreferencePage";
import { hasVisibleChrons } from "Everlaw/SmartOnboarding/RecommendationUtil";
import * as React from "react";

/**
 * Use when you want a {@link Recommendation}'s {@link Recommendation#supportedPages} to include
 * every page in {@link RecommendationNavigationPage} except for the pages passed into `toExclude`.
 */
function excludes(toExclude: RecommendationNavigationPage[]): RecommendationNavigationPage[] {
    const toExcludeSet = new Set(toExclude);
    return Object.values(RecommendationNavigationPage).filter((page) => !toExcludeSet.has(page));
}

/**
 * Static information about {@link Recommendation recommendations}. Constant names should be kept
 * consistent with Recommendation.java.
 */
export const Recommendations = {
    ACTIVATE_DEDUPLICATION: new Recommendation({
        displayName: "Deduplicate search",
        messages: [
            "Your search includes duplicate documents. You can save time by reviewing only "
                + "one copy of each document. Select Settings to deduplicate your results.",
            "Choose a deduplication setting from Deduplicate among search hits",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        supportedPages: [RecommendationNavigationPage.RESULTS_TABLE],
    }),
    ADD_CODES_CATEGORIES: new Recommendation({
        displayName: "Add codes category",
        messages: [
            "Create codes for your documents in Project Settings",
            "You can find all codes for your project in this tab",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.ProjectSettings,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canProjectAdmin,
    }),
    ADD_DOC_TO_STORYBUILDER: new Recommendation({
        displayName: "Add document to Storybuilder",
        messages: [
            "Adding important documents to your Story during review gives your team a head "
                + "start on building a case narrative. Click on the Story button to add "
                + "this document to your Timeline.",
            "Click on this button to add the document to your story",
            "Click next to navigate to the Storybuilder Page and see the new document",
            "Start the tutorial to learn more about leveraging Storybuilder throughout your case",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.ReviewWindow,
        navigationPage: RecommendationNavigationPage.REVIEW,
        supportedPages: [RecommendationNavigationPage.REVIEW],
        hasPermission: (props) => props.userPerms.canReceiveSB,
        shouldCheckChronAccess: true,
    }),
    ADD_NOTE: new Recommendation({
        displayName: "Add Note to Document",
        messages: [
            "Notes can be added to documents to annotate or highlight relevant information",
            "To add notes to documents, you’ll need to edit your review window and add the annotations tab",
            "Select the annotations tab",
            "Click here to finish editing",
            "Click here to view the annotations tab",
            "You can add a note to a document in the annotations tab",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.ReviewWindow,
        supportedPages: [RecommendationNavigationPage.REVIEW],
    }),
    ADD_STORYBUILDER_TAB: new Recommendation({
        displayName: "Add Storybuilder tab",
        messages: [
            "Edit your layout to add the Storybuilder Panel to your review window layout",
            "Select or drag this tab to a group, or create a new group",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        navigationPage: RecommendationNavigationPage.REVIEW,
        supportedPages: [RecommendationNavigationPage.REVIEW],
    }),
    ADD_TESTIMONY: new Recommendation({
        displayName: "Add testimony",
        messages: [
            "Now that a transcript has been added to your deposition, you can elevate key "
                + "quotes as testimony. These quotes can be organized in your Timeline and cited "
                + "in future Depositions. Start the tutorial to learn more.",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        navigationPage: RecommendationNavigationPage.DEPOSITION,
        supportedPages: [RecommendationNavigationPage.DEPOSITION],
        hasPermission: (props) => props.userPerms.canReceiveSB,
        shouldCheckChronAccess: true,
    }),
    ADD_USERS_TO_ASSIGNMENT: new Recommendation({
        displayName: "Add users to assignment",
        messages: [
            "Assignment groups facilitate efficient document review. Create an assignment group "
                + "to organize review workflows, give instructions to your reviewers, and track "
                + "progress overall. Start tutorial to learn more.",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.AssignmentGroups,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: [RecommendationNavigationPage.HOME],
        hasPermission: (props) => props.userPerms.canCreateAGs,
    }),
    BATCH_MODIFY: new Recommendation({
        displayName: "Batch modify",
        messages: [
            "There are some patterns in your review work. Review multiple documents at once "
                + "using batch modification. Select any subset of documents from this results table "
                + "and select Batch.",
            "Select modify",
            "Here, you can add or remove codes, notes, ratings, binders, notes, and more. "
                + "All changes will be applied to all selected documents.",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        supportedPages: [RecommendationNavigationPage.RESULTS_TABLE],
    }),
    CHECK_OUT_ASSIGNMENT: new Recommendation({
        displayName: "Check out assignment",
        messages: [
            "You've been assigned documents to review! This card contains additional "
                + "information about your assignment, such as the review criteria.",
            "This results table lists your assigned documents and highlights key metadata. "
                + "Select a row to open the review window and apply review work.",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.AssignmentGroups,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: [RecommendationNavigationPage.HOME],
    }),
    CHECK_OUT_PC: new Recommendation({
        displayName: "Check out predictive coding",
        messages: [
            "Congratulations on finding 50 hot or warm documents in your project. "
                + "To speed this process up, you can create a predictive coding model by clicking here.",
            "Now select Predictive Coding",
            "Click here to start a tutorial on setting up a Predictive Coding model",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.PredictiveCoding,
        navigationPage: RecommendationNavigationPage.PREDICTIVE_CODING_WIZARD,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canCreatePC,
    }),
    CREATE_FOLDER: new Recommendation({
        displayName: "Create homepage folder",
        messages: [
            "Now that you've shared or received an object, you may want to add it to a folder on the homepage",
            "First, head back to your homepage by clicking the homepage icon",
            "Click here to add a new folder",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Homepage,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
    }),
    CREATE_GROUPS_ADD_USERS: new Recommendation({
        displayName: "Create user groups",
        messages: [
            "Before adding users to your project, create user groups to manage their "
                + "permissions. Select the Project Management button.",
            "Now select Project Settings",
            "Templates help you quickly create a user group for common roles. You can "
                + "further customize the permissions from the Permissions tab. "
                + "Select Create when you're done.",
            "Click here when you've finished creating a new group",
            "When you're ready to add users to your project, select the Users tab",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Homepage,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canProjectAdmin,
    }),
    CREATE_PERSISTENT_HIGHLIGHTS: new Recommendation({
        displayName: "Create persistent highlights",
        messages: [
            "Persistent Hits are content highlights that appear for all users in the project. "
                + "Create them to draw attention to important key words that inform review decisions, "
                + "like search terms or PII.",
            "Click here to navigate to project settings, where the Persistent Highlight menu is kept",
            "Select General",
            "Select Persistent Highlights",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.ProjectSettings,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: excludes([
            RecommendationNavigationPage.REVIEW,
            RecommendationNavigationPage.SETTINGS,
        ]),
        hasPermission: (props) => props.userPerms.canProjectAdmin,
    }),
    CREATE_PRODUCTION_PROTOCOL: new Recommendation({
        displayName: "Create production protocol",
        messages: [
            React.createElement(
                "span",
                null,
                "After your documents have been uploaded and you've created codes, you can set up your production protocols here. ",
                React.createElement(Link, {
                    href: "https://support.everlaw.com/hc/en-us/articles/360000037892-Productions-1-of-3-Creating-a-Production-Protocol",
                    children:
                        "Learn more about production protocols and protocol templates by clicking here.",
                }),
            ),
            "Now select Production Protocols",
            "Click here to start a tutorial on creating a Production protocol",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Productions,
        supportedPages: [RecommendationNavigationPage.DATA],
        hasPermission: (props) => props.userPerms.canShareProductions,
    }),
    CREATE_REDACTION_STAMPS: new Recommendation({
        displayName: "Create redaction stamps",
        messages: [
            "You can add project-level redaction stamps in Project Settings. "
                + "To add stamps, first click on this Project Management icon.",
            "Click here to access Project Settings",
            "Redaction stamp tools are located in the General tab",
            "Enter your Redaction Stamps here",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.ProjectSettings,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canProjectAdmin,
    }),
    CREATE_STR: new Recommendation({
        displayName: "Create a Search Term Report",
        messages: [
            "Create a Search Term Report (STR) to view the results of multiple content and/or "
                + "metadata searches simultaneously. Use STRs for early case assessment and to "
                + "prioritize document review.",
            "Click here to access the Search Term Report Page",
            "To learn how to set up a Search Term Report, complete the tutorial",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.SearchTermReports,
        navigationPage: RecommendationNavigationPage.SEARCH_TERM_REPORT_CREATE,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canCreateSTRs,
    }),
    CUSTOMIZE_REVIEW_LAYOUT: new Recommendation({
        displayName: "Customize review layout",
        messages: [
            "Customize your review window layout to organize your most commonly used tools and hide the rest",
            "Select Save",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.ReviewWindow,
        supportedPages: [RecommendationNavigationPage.REVIEW],
    }),
    DEFAULT_REVIEW_LAYOUT: new Recommendation({
        displayName: "Share a Review Layout",
        messages: [
            "Share your optimized view with your reviewers. Select Save to choose the default layout.",
            "Select an existing layout or create a new one",
            "Check Make this layout the project default",
            "Select save",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.ReviewWindow,
        supportedPages: [RecommendationNavigationPage.REVIEW],
    }),
    DISABLE_RECOMMENDATIONS: new Recommendation({
        displayName: "Disable recommendations",
        messages: [
            "You can turn off smart recommendations at any time by going to recommendation "
                + "settings here",
        ],
        pagePref: RecommendationPreferencePage.Homepage,
        recLevel: RecommendationLevel.USER_LEVEL,
        supportedPages: excludes([]),
    }),
    DO_PRODUCTION_MODIFICATION: new Recommendation({
        displayName: "Production modifications",
        messages: [
            "Did you mean to modify this production? Everlaw has production modification tools "
                + "that will allow you to fix your production without fully deleting it.",
            "Click here to find production modification tools",
            "Select Modify Production",
            React.createElement(
                "span",
                null,
                "To learn more about modifying productions, check out ",
                React.createElement(Link, {
                    href: "https://support.everlaw.com/hc/en-us/articles/115001517212-Production-Modification-Tools#:~:text=To%20begin%2C%20click%20on%20the,the%20configuration%2C%20save%20the%20configuration",
                    children: "this support article",
                }),
            ),
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Productions,
        supportedPages: [RecommendationNavigationPage.PRODUCTIONS],
        hasPermission: (props) => props.userPerms.canAdminProductions,
    }),
    EXHIBIT_LIST_TUTORIAL: new Recommendation({
        displayName: "Exhibit list tutorial",
        messages: [
            "Now that you've scheduled a deposition, you may want to learn about exhibit lists on Everlaw. "
                + "Click here to walk through a tutorial about exhibit lists.",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        navigationPage: RecommendationNavigationPage.DEPOSITION,
        supportedPages: [RecommendationNavigationPage.DEPOSITION],
        hasPermission: (props) => props.userPerms.canReceiveSB,
        shouldCheckChronAccess: true,
    }),
    OPEN_DATA_VISUALIZER: new Recommendation({
        displayName: "Open data visualizer",
        messages: [
            "Visualize these search results using Data Visualizer. Gain insights at a glance "
                + "and further narrow your results down to those with important characteristics. "
                + "Select the Data Visualizer button.",
            "Start the tutorial to walk through Data Visualizer",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        supportedPages: [RecommendationNavigationPage.RESULTS_TABLE],
    }),
    PEOPLE_PROFILES: new Recommendation({
        displayName: "Add People Profiles",
        messages: [
            "Now that you’ve added documents to your Story, "
                + "you may want to add People profiles to your Story. Click here to open the Storybuilder menu.",
            "Click here to go to a Storybuilder chronology",
            "Click here to go to the Timeline tab",
            "Add People here",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: excludes([
            RecommendationNavigationPage.REVIEW,
            RecommendationNavigationPage.CHRON,
        ]),
        hasPermission: (props) => props.userPerms.canReceiveSB,
        shouldCheckChronAccess: true,
    }),
    POST_CREATE_NEW_ASSIGNMENT_GROUP: new Recommendation({
        displayName: "Create new assignment group",
        messages: [
            "Congrats! Your reviewers have completed their assignments. If you'd like to "
                + "create another assignment group, click next.",
            "Select Create new to get started with your next assignment group. For a refresher "
                + "on how to create one, select Start Tutorial.",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.AssignmentGroups,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: [RecommendationNavigationPage.HOME],
        hasPermission: (props) => props.userPerms.canCreateAGs,
    }),
    RECOMMEND_TO_CLUSTER: new Recommendation({
        displayName: "Document clustering",
        messages: [
            "With at least 1000 documents with enough text in your project, Everlaw can predict "
                + "which documents are related. Click here to check out document clustering.",
            "Now select Clustering",
            "Select documents to cluster",
            "Click here to kick off clustering on the documents you've selected",
            "Clustering has been turned on for this project. You can leave this page and "
                + "we'll notify you when your clusters are ready to be viewed.",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.DocumentClustering,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        navigationPage: RecommendationNavigationPage.HOME,
        hasPermission: (props) => props.userPerms.canAdminClustering,
    }),
    RECOMMEND_TO_VIEW_ANALYTICS: new Recommendation({
        displayName: "View analytics",
        messages: [
            "Understand review activity and progress to better manage your document review. "
                + "Select Project Management.",
            "Select Project Analytics",
            "The Project Overview tab gives you a snapshot of your project's document review so "
                + "far. Select Review Activity to assess individual reviewer performance.",
            "To learn more about project analytics, start this tutorial",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Analytics,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        navigationPage: RecommendationNavigationPage.HOME,
        hasPermission: (props) => props.userPerms.canAnalytics,
    }),
    RECOMMEND_TO_VIEW_NEW_USERS: new Recommendation({
        displayName: "View new users",
        messages: [
            "Other administrators have added additional users to this project. "
                + "To view these users, first click on this Project Management icon.",
            "Click here to access Project Settings",
            "Now click on the the Users tab. New users will appear here.",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.ProjectSettings,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canProjectAdmin,
    }),
    SEARCH_BY_PARTIES: new Recommendation({
        displayName: "Search by parties",
        messages: [
            "The Parties search term searches across the To, From, Cc, and Bcc fields at once. "
                + "This simplifies building a search for emails between key entities.",
            "Select Show more terms to access the Parties search term",
        ],
        recLevel: RecommendationLevel.EVERYTIME_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        supportedPages: [RecommendationNavigationPage.SEARCH_BUILDER],
        navigationPage: RecommendationNavigationPage.SEARCH_BUILDER,
    }),
    SEARCH_BY_PROJECT: new Recommendation({
        displayName: "Search by project",
        messages: [
            "Now that you have two projects in the database, you can use the Projects search term. "
                + "The Projects search term limits searches to uploads made to specific projects. Click "
                + "here to access the query builder.",
            "The Projects search term is in the Document section of search terms. Click here to access it.",
            "Click here to add the Projects term to your search",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        navigationPage: RecommendationNavigationPage.DATABASE_SETTINGS,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canDbAdmin,
    }),
    SELF_ASSIGN: new Recommendation({
        displayName: "Self-assign",
        messages: [
            "Congratulations on finishing your first assignment! Select the plus button to "
                + "claim your next batch of documents",
            "Click on this assignment to assign yourself more documents from it",
            "Click here when you've chosen the number of documents you'd like to self assign",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.AssignmentGroups,
        supportedPages: [RecommendationNavigationPage.HOME],
        navigationPage: RecommendationNavigationPage.HOME,
    }),
    SET_UP_AUTOCODE: new Recommendation({
        displayName: "Set up autocode",
        messages: [
            "Auto-code rules ensure all documents in a given context "
                + "(e.g. attachments or duplicates) are automatically coded the same way. "
                + "This standardization reduces time spent on manual coding. Select Auto-Code Rules.",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.ProjectSettings,
        supportedPages: [RecommendationNavigationPage.SETTINGS],
        hasPermission: (props) => props.userPerms.canProjectAdmin,
    }),
    SET_UP_CODING_PRESETS: new Recommendation({
        displayName: "Set up coding presets",
        messages: [
            "Coding presets are custom shortcuts that can apply several codes, ratings, "
                + "binders, or notes with a single click. Select Next to go to the coding presets tab.",
            "To set up coding presets, you’ll need to edit your review window to add the coding presets tab",
            "First, add the coding presets tab to the panel",
            'Finish editing by clicking "Done"',
            "The coding presets tab shows all your saved presets",
            "Select the pencil to create a preset",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.ReviewWindow,
        supportedPages: [RecommendationNavigationPage.REVIEW],
    }),
    SET_UP_CONDITIONAL_CODES: new Recommendation({
        displayName: "Set up conditional codes",
        messages: [
            "You can create conditional coding rules to prevent certain codes from being "
                + "applied unless conditions are met. Click here to create and view them.",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.ProjectSettings,
        supportedPages: [RecommendationNavigationPage.SETTINGS],
        hasPermission: (props) => props.userPerms.canProjectAdmin,
    }),
    SET_UP_SEARCH_GROUPING: new Recommendation({
        displayName: "Set up search grouping",
        messages: [
            "Now that you've created a search, you may want to include grouping options. Grouping "
                + "options will allow you to group by duplicate family, attachment family, and more.",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        supportedPages: [RecommendationNavigationPage.RESULTS_TABLE],
    }),
    SHARE_BINDER: new Recommendation({
        displayName: "Share a binder",
        messages: ["You can share this binder from this 3 dot menu"],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Homepage,
        supportedPages: [RecommendationNavigationPage.HOME],
    }),
    SHARE_FOLDER: new Recommendation({
        displayName: "Share homepage folder",
        messages: [
            "Folders are collaborative objects that can be shared. Open your folder to begin.",
            "Select Share. You can choose both who to share the folder with, and which level "
                + "of permissions they should have.",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Homepage,
        supportedPages: [RecommendationNavigationPage.HOME],
    }),
    SHARE_PRODUCTION: new Recommendation({
        displayName: "Share a production",
        messages: [
            "After creating a production, you may want to share that production with other "
                + "users. Click here to share your production.",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Productions,
        supportedPages: [RecommendationNavigationPage.PRODUCTIONS],
        hasPermission: (props) => props.userPerms.canShareProductions,
    }),
    SMART_ONBOARDING_INTRO: new Recommendation({
        displayName: "Introduction to Smart Onboarding",
        messages: [
            React.createElement(
                "span",
                null,
                "Smart recommendations are enabled in this project!",
                React.createElement("br"),
                React.createElement("br"),
                "To view recommendations you've already seen, disable recommendations, or search "
                    + "through relevant articles and videos for additional help, click on this icon.",
            ),
            "From this menu, you can view all recommendations relevant to this page, or click on "
                + "the Settings icon to disable recommendations.",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Homepage,
        supportedPages: excludes([]),
    }),
    STORYBUILDER_TODO_LIST_ADD: new Recommendation({
        displayName: "Storybuilder to-do list",
        messages: [
            "With a deposition scheduled, you may also want to add items to your Storybuilder to-do list",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        supportedPages: [RecommendationNavigationPage.SB_DASHBOARD],
        hasPermission: (props) => props.userPerms.canReceiveSB,
        shouldCheckChronAccess: true,
    }),
    STORY_LABELS_EVENTS: new Recommendation({
        displayName: "Storybuilder Timeline",
        messages: [
            "Once key documents are added to your Story, sort and label them from your "
                + "Timeline to build your case narrative. Select the Storybuilder button to learn more.",
            "Select a Story",
            "Select Timeline",
            "To learn how Timeline works, click here to start a tutorial",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        supportedPages: excludes([
            RecommendationNavigationPage.REVIEW,
            RecommendationNavigationPage.CHRON,
        ]),
        hasPermission: (props) => props.userPerms.canReceiveSB,
        shouldCheckChronAccess: true,
    }),
    TEST_REC: new Recommendation({
        displayName: "Test recommendation",
        messages: [
            "This is an activated recommendation. Notice that it may point to a frontend element. "
                + "To advance the rec, click next.",
            "You can also have recommendations render as toasts. Click Done to finish the recommendation.",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Test,
        supportedPages: [RecommendationNavigationPage.STYLE],
    }),
    TRANSFER_WORK_PRODUCT: new Recommendation({
        displayName: "Transfer work product",
        messages: [
            "We've noticed that you have documents on multiple projects. "
                + "To learn how to transfer work product from the other project, start a walkthrough tutorial.",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        supportedPages: [RecommendationNavigationPage.RESULTS_TABLE],
    }),
    UNITIZE_DOCUMENT: new Recommendation({
        displayName: "Unitize document",
        messages: [
            "If you need to split this document up into several documents, select the "
                + "unitization tool from the context panel",
        ],
        recLevel: RecommendationLevel.EVERYTIME_LEVEL,
        pagePref: RecommendationPreferencePage.ReviewWindow,
        supportedPages: [RecommendationNavigationPage.REVIEW],
        hasPermission: (props) => props.userPerms.canUnitize,
    }),
    VIEW_DEPOSITION: new Recommendation({
        displayName: "View Deposition",
        messages: [
            "A deposition has been scheduled by a user on your project. View the deposition by clicking here.",
            "Now click here to select your Storybuilder chronology",
            "Click here",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canReceiveSB,
        shouldCheckChronAccess: true,
    }),
    VIEW_KEYBOARD_SHORTCUTS: new Recommendation({
        displayName: "View keyboard shortcuts",
        messages: ['To view keyboard shortcuts for any page, press "?" on your keyboard'],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.ReviewWindow,
        supportedPages: [RecommendationNavigationPage.REVIEW],
    }),
    VIEW_NEW_CODES: new Recommendation({
        displayName: "View new codes",
        messages: [
            "A user on your project has added new codes. To view these codes, "
                + "first click on this Project Management icon.",
            "Click here to access Project Settings",
            "Select the Codes tab to see the new codes",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Homepage,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        navigationPage: RecommendationNavigationPage.HOME,
        hasPermission: (props) => props.userPerms.canProjectAdmin,
    }),
    VIEW_NEW_DOCS: new Recommendation({
        displayName: "View new documents",
        messages: [
            "A user on this project has uploaded additional documents. To view this upload, click on the data transfer menu first.",
            "Now click on the uploads tab",
            "To view the natively uploaded documents, click the Native Data Tab",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Uploads,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canUpload,
    }),
    VIEW_SAVING: new Recommendation({
        displayName: "View saving",
        messages: [
            "Customize the information displayed in your results table to view the most "
                + "important information about your documents. Select the View button.",
            "Select Add or remove columns",
            "Select the columns you want to see in the results table. Then select Save.",
            "Open the menu again to save your view",
            "Select Save view",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        supportedPages: [RecommendationNavigationPage.RESULTS_TABLE],
    }),
    WELCOME_TO_EVERLAW: new Recommendation({
        displayName: "Welcome to Everlaw",
        messages: [
            "Welcome to Everlaw! Smart recommendations are enabled in this project. To view "
                + "recommendations you've already seen, disable recommendations, or search "
                + "through relevant articles and videos for additional help, click on this icon.",
            "From this dialog, you can view all recommendations relevant to this page, or "
                + "click on the Settings icon to disable recommendations",
            "You can disable all recommendations in the project, or recommendations on a "
                + 'specific topic or page. Once you\'ve finished selecting which recommendations to disable, select "Done."',
            "To get started on your project, click here to start a tutorial on the homepage.",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Homepage,
        supportedPages: excludes([]),
    }),
} as const satisfies Record<string, Recommendation>;

Object.keys(Recommendations).forEach((key) => {
    const recConstantKey = key as keyof typeof Recommendations;
    const recommendation = Recommendations[recConstantKey];
    recommendation.setRecommendationKey(recConstantKey);
});

/**
 * Gets {@link Recommendation recommendations} the current user has minimum permissions to view.
 */
export function getRecommendationsWithPermissions(): Recommendation[] {
    const userPerms = checkPermissions();
    const chronsVisible = hasVisibleChrons();
    const perms = { userPerms, hasVisibleChrons: () => chronsVisible };
    return Object.values(Recommendations).filter((rec) => rec.hasPermission(perms));
}
