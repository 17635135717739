import Arr = require("Everlaw/Core/Arr");
import Base = require("Everlaw/Base");
import { ColorTokens } from "design-system";
import * as Dom from "Everlaw/Dom";
import { MultiMatterModel } from "Everlaw/MultiMatterModels/MultiMatterModel";
import * as ActionNode from "Everlaw/UI/ActionNode";
import * as Icon from "Everlaw/UI/Icon";
import ElevatedRoleConfirm = require("Everlaw/ElevatedRoleConfirm");
import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");
import { Color } from "Everlaw/ColorUtil";
import Is = require("Everlaw/Core/Is");

class PredictionModel extends Base.SecuredObject {
    private static UPDATE_TIME_HOUR = 3;
    get className() {
        return "PredictionModel";
    }
    override id: PredictionModel.Id;
    project: Project.Id;
    name: string;
    lastUpdated?: number;
    reviewedEql: string;
    relevantEql: string;
    excludeEql: string;
    reviewedDescription: string;
    relevantDescription: string;
    excludeDescription: string;
    histogram: number[];
    unreviewedHistogram: number[];
    confidence: number[];
    numRelevant: number;
    numIrrelevant: number;
    newRelevant: number;
    newIrrelevant: number;
    repredict: boolean;
    editingState: PredictionModel.EditingState;
    status: PredictionModel.UpdateState;
    usingPrefix: boolean;
    eqlAccess: boolean; // used on the analytics page to determine whether model can be displayed
    editHistory: PredictionModel.PredictionModelEditHistoryEntry[];
    multiMatterModelId: number;
    constructor(params: any) {
        super(params);
        Object.assign(this, params);
        if (Is.defined(params.projectStatus)) {
            this.status = params.projectStatus.status;
        }
    }

    override _mixin(params: any) {
        Object.assign(this, params);
    }

    override display() {
        return this.name;
    }

    remove(callback: (pm: PredictionModel) => void) {
        Rest.post("prediction/deletePredictionModel.rest", {
            modelId: this.id,
        }).then(() => {
            Base.remove(this);
            if (this.isMultiMatterModel()) {
                Base.get(MultiMatterModel, this.multiMatterModelId)?.updateProjectIds(
                    this.project,
                    false,
                );
            }
            callback && callback(this);
        });
    }

    @ElevatedRoleConfirm("updating a model")
    update(success?: (pm: PredictionModel) => void) {
        Rest.post("prediction/updatePrediction.rest").then(({ status }) => {
            this.status = status;
            Base.publish(this);
            success && success(this);
        });
    }
    isIdle() {
        return this.status === PredictionModel.UpdateState.IDLE;
    }
    isQueued() {
        return this.status === PredictionModel.UpdateState.QUEUED;
    }
    isUpdating() {
        return (
            this.status === PredictionModel.UpdateState.UPDATING
            || this.status === PredictionModel.UpdateState.RUNNING
        );
    }
    isEditing() {
        return this.editingState !== PredictionModel.EditingState.NOT_EDITING;
    }
    editingHadResults() {
        return this.editingState === PredictionModel.EditingState.EDITING_OVERWRITE;
    }
    getSortSignature(descending: boolean): [any[], boolean] {
        return [["modelPrediction", this.id], !!descending];
    }
    /** Whether this is a custom model or an auto generated rating based model. */
    isCustom() {
        // Assumes that a model is autogenerated iff the model name is named "Rating" However, this
        // isn't always true. A user could delete/edit the "Rating" model and could then rename
        // another model "Rating". We currently only use this method for classifying ga events, so
        // it's okay this isn't completely accurate.
        return this.name !== "Rating";
    }
    hasResults() {
        return this.histogram && this.unreviewedHistogram;
    }

    hasNextUpdateTime(): boolean {
        return !!this.lastUpdated && this.isIdle();
    }

    getNextUpdateTime(): Date {
        if (!this.hasNextUpdateTime()) {
            return null;
        }
        const updateTime = new Date();
        if (updateTime.getUTCHours() >= PredictionModel.UPDATE_TIME_HOUR) {
            // If it's exactly 3am UTC the next update will be tomorrow,
            // this ensures we round up to UTC hour 3, rather than down.
            updateTime.setUTCDate(updateTime.getUTCDate() + 1); // increments over months correctly
        }
        updateTime.setUTCHours(PredictionModel.UPDATE_TIME_HOUR, 0, 0, 0);
        return updateTime;
    }

    getProject(): Project {
        return Base.get(Project, this.project);
    }

    isMultiMatterModel(): boolean {
        return !!this.multiMatterModelId;
    }

    displayModelType(): string {
        return this.isMultiMatterModel() ? "multi-matter model" : "prediction model";
    }
}

module PredictionModel {
    export type Id = number & Base.Id<"PredictionModel">;

    export const COLOR = Color.fromEverColor(ColorTokens.OBJECT_PREDICTION_MODEL);

    export enum EditingState {
        NOT_EDITING = "NOT_EDITING",
        EDITING_NEW = "EDITING_NEW",
        EDITING_OVERWRITE = "EDITING_OVERWRITE",
    }

    export enum UpdateState {
        IDLE = "IDLE",
        QUEUED = "QUEUED",
        RUNNING = "RUNNING",
        UPDATING = "UPDATING",
    }

    export enum BasicReviewState {
        INSUFFICIENT,
        SAMPLED,
        SUFFICIENT,
    }

    export enum RigorousReviewState {
        INSUFFICIENT,
        SUFFICIENT,
    }

    export function displayStatus(state: UpdateState) {
        if (state === UpdateState.IDLE) {
            return "Idle";
        } else if (state === UpdateState.QUEUED) {
            return "Queued for update";
        } else if (state === UpdateState.RUNNING || state == UpdateState.UPDATING) {
            return "Updating";
        }
    }

    // Get the first prediction model by id.  This will generally be the default rating model.
    export function getBaseModel() {
        return Arr.sort(Base.get(PredictionModel), {
            key: function (m) {
                return m.id;
            },
        })[0];
    }

    export interface ModelInfo {
        history: Record[];
        highHoldoutSize: number;
        lowHoldoutSize: number;
        highPrefixHoldoutSize: number;
        lowPrefixHoldoutSize: number;
        highTrainSize: number;
        lowTrainSize: number;
        unreviewedHoldoutSize: number;
        unreviewedTrainSize: number;
        ineligibleSize: number;
        excludedSize?: number;
        projectStatus: ProjectStatus;
    }

    export interface ProjectStatus {
        status: UpdateState;
        lastUpdated?: string;
        hostId?: string;
    }

    export interface Record {
        timestamp: number;
        highTrainSize: number;
        lowTrainSize: number;
        basic: Performance;
        rigorous: Performance;
        sampled: boolean;
    }

    export interface Performance {
        highHoldoutSize: number;
        lowHoldoutSize: number;
        // There won't be performance metrics if the size is insufficient
        metrics?: PerformanceMetrics;
    }

    export interface PerformanceMetrics {
        cutoff: number;
        f1: number[];
        precision: number[];
        recall: number[];
        truePositives: number[];
        trueNegatives: number[];
        falsePositives: number[];
        falseNegatives: number[];
    }

    export interface PredictionModelEditHistoryEntry {
        user: string;
        timestamp: number;
        name?: string[];
        reviewed?: string[];
        relevant?: string[];
        exclude?: string[];
    }

    export function createLearnMoreLink(forMultiMatterModel = true, addIcon?: boolean): ActionNode {
        const learnMoreLink = ActionNode.textAction(
            "Learn more",
            undefined,
            undefined,
            forMultiMatterModel
                ? "https://support.everlaw.com/hc/en-us/articles/21871174749467"
                : "https://support.everlaw.com/hc/en-us/articles/205954365",
            true,
            "multi-matter-model-learn-more-link",
            false,
            true,
        );

        if (addIcon) {
            const icon = new Icon("arrow-up-right-blue-20");
            learnMoreLink.registerDestroyable(icon);
            Dom.place(Dom.node(icon), learnMoreLink);
        }

        return learnMoreLink;
    }
}

export = PredictionModel;
