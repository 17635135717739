import Preference from "Everlaw/Preference";
import { RecommendationNavigationPage } from "Everlaw/SmartOnboarding/RecommendationNavigationPage";

/**
 * {@link Preference#RECPAGES#AllPages} should not be assignable to a {@link Recommendation}.
 */
type PageName = Exclude<keyof typeof Preference.RECPAGES, "AllPages">;

export interface PreferencePage {
    /**
     * This corresponds to a key in the {@link Preference#RECPAGES} object.
     */
    name: PageName;
    page: RecommendationNavigationPage;
    /**
     * The user-friendly name of the page. If not provided, the {@link pageName} will be
     * used as a display name.
     */
    displayName?: string;
}

export const RecommendationPreferencePage: { [k in PageName]: PreferencePage } = {
    Analytics: {
        name: "Analytics",
        page: RecommendationNavigationPage.ANALYTICS,
    },
    Homepage: {
        name: "Homepage",
        page: RecommendationNavigationPage.HOME,
    },
    Uploads: {
        name: "Uploads",
        page: RecommendationNavigationPage.NATIVE_UPLOADS,
    },
    Productions: {
        name: "Productions",
        page: RecommendationNavigationPage.PRODUCTIONS,
    },
    Search: {
        name: "Search",
        page: RecommendationNavigationPage.RESULTS_TABLE,
    },
    SearchTermReports: {
        name: "SearchTermReports",
        displayName: "Search term reports",
        page: RecommendationNavigationPage.SEARCH_TERM_REPORT,
    },
    DataVisualizer: {
        name: "DataVisualizer",
        displayName: "Data visualizer",
        page: RecommendationNavigationPage.RESULTS_TABLE,
    },
    PredictiveCoding: {
        name: "PredictiveCoding",
        displayName: "Predictive coding",
        page: RecommendationNavigationPage.PREDICTIVE_CODING,
    },
    DocumentClustering: {
        name: "DocumentClustering",
        displayName: "Document clustering",
        page: RecommendationNavigationPage.CLUSTERING,
    },
    Storybuilder: {
        name: "Storybuilder",
        page: RecommendationNavigationPage.CHRON,
    },
    ReviewWindow: {
        name: "ReviewWindow",
        displayName: "Review window",
        page: RecommendationNavigationPage.REVIEW,
    },
    MessageCenter: {
        name: "MessageCenter",
        displayName: "Message center",
        page: RecommendationNavigationPage.MESSAGES,
    },
    ProjectSettings: {
        name: "ProjectSettings",
        displayName: "Project settings",
        page: RecommendationNavigationPage.SETTINGS,
    },
    DatabaseSettings: {
        name: "DatabaseSettings",
        displayName: "Database settings",
        page: RecommendationNavigationPage.DATABASE_SETTINGS,
    },
    AssignmentGroups: {
        name: "AssignmentGroups",
        displayName: "Assignment groups",
        page: RecommendationNavigationPage.ASSIGNMENTS,
    },
    Test: {
        name: "Test",
        displayName: "Test",
        page: RecommendationNavigationPage.STYLE,
    },
} as const;
