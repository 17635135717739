import Bugsnag = require("Everlaw/Bugsnag");
import Dom = require("Everlaw/Dom");
import Input = require("Everlaw/Input");
import UrlHash = require("Everlaw/UrlHash");
import Util = require("Everlaw/Util");
import dojo_on = require("dojo/on");
import { makeFocusable } from "Everlaw/UI/FocusDiv";

export interface TabbedContentEntry {
    /** The ID of the tab. */
    id: string;
    /** The clickable tab element for the tab bar. */
    tab: HTMLElement;
    /** The div element that corresponds to the tab element. */
    content: HTMLElement;
}

export interface TabbedContentParams {
    /**
     * Whether to select a tab during construction.
     */
    initiallyEmpty?: boolean;
    /**
     * The ID of the initially-selected tab. If not specified, the first tab is selected by default.
     */
    initial?: string;
    /**
     * When provided, the ID of currently-selected tab will be read from and written to
     * location.hash as `$hashState=$tabId`. When there is no such value in the hash, the
     * `initialTab` will be used as the initially-selected tab.
     */
    hashState?: string;
    /**
     * If true, the initial tab construction will not set the hash. This param is only relevant when
     * initiallyEmpty is false and hashSate is set.
     */
    ignoreInitialHash?: boolean;
    /**
     * When a tab is selected, this function is called with three arguments:
     *  - tabId:    the newly-exposed tab
     *  - oldTabId: the newly-hidden tab; undefined on the first selection
     *  - first: true if this is the first time the tab is selected
     */
    onShow?: (tabId: string, oldTabId: string, first: boolean) => void;
    /**
     * The category to use for logging google analytics events when clicking on a tab. The action of
     * the event is the textContent of the tab node. If null, then no ga_event is created.
     */
    gaCategory?: string;
    makeFocusable?: boolean;
    focusStyling?: string | string[];
}

/**
 * Provides a way for a user to click on one of a given set of tabs to show a corresponding pane of
 * content.
 */
export class TabbedContent {
    /**
     * The ID of the currently-selected tab. It remains undefined until the completion of
     * construction. This is important for proper operation of hashChange during construction.
     */
    protected currentId: string;
    protected entries: Map<string, TabbedContentEntry> = new Map();
    private previouslySelected: Map<string, boolean> = new Map();
    private tabDestroyables: Map<string, Util.Destroyable[]> = new Map();
    private destroyables: Util.Destroyable[] = [];
    private focusable: boolean = false;
    private focusStyling: string | string[];
    constructor(
        entries: TabbedContentEntry[],
        protected params: TabbedContentParams = {},
    ) {
        this.init(params);
        this.focusable = params.makeFocusable;
        this.focusStyling = params.focusStyling;
        entries.forEach((entry) => this.addTab(entry));
        if (params.hashState) {
            this.destroyables.push(UrlHash.subscribe((hash) => this.hashChange(hash)));
            if (!params.initiallyEmpty && this.hashChange(UrlHash.get())) {
                // initial tab set from hash
                return;
            } // else no tab in hash; just fall through to normal initial tab selection
        }
        if (!params.initiallyEmpty) {
            this.select(params.initial || entries[0].id, !!params.ignoreInitialHash);
        }
    }
    protected init(params: HorizontalTabBarParams) {}
    /**
     * Sets the tab based on the value in the hash, if it differs from the currentTab or the
     * currentTab is not set yet. If there is no tab value in the hash, returns false.
     */
    private hashChange(hash: UrlHash.Hash) {
        const tabId = hash[this.params.hashState] || this.params.initial;
        const entry = this.entries.get(tabId);
        if (!entry) {
            return false;
        }
        if (this.currentId === tabId) {
            // The tab in the hash already matches the one that is selected (called as a result of
            // the update in select?); no more work to do.
            return true;
        }
        // Don't allow selection of a hidden tab.
        if (Dom.isHidden(entry.tab)) {
            return false;
        }
        this.select(tabId);
        return true;
    }
    getEntry(id: string) {
        return this.entries.get(id);
    }
    getCurrentId() {
        return this.currentId;
    }
    select(id: string, ignoreHash = false) {
        var entry = this.entries.get(id);
        if (!entry || Dom.hasClass(entry.tab, "disabled")) {
            return;
        }
        this.params.gaCategory && ga_event(this.params.gaCategory, entry.tab.textContent);
        this.entries.forEach((entry: TabbedContentEntry, entryId: string) => {
            Dom.toggleClass(entry.tab, "selected", entryId === id);
            this.showEntry(entry, entryId === id);
        });
        const oldId = this.currentId;
        this.currentId = id;
        this.params.onShow && this.params.onShow(id, oldId, !this.previouslySelected.get(id));
        this.previouslySelected.set(id, true);
        // This must come after we set currentTab to avoid an infinite callback loop.
        if (this.params.hashState && !ignoreHash) {
            // This will trigger hashChange, but currentTab will match and it will exit.
            if (this.currentId === this.params.initial) {
                UrlHash.remove(this.params.hashState);
            } else {
                UrlHash.add({ [this.params.hashState]: this.currentId });
            }
        }
    }
    protected showEntry(entry: TabbedContentEntry, show: boolean) {
        Dom.show(entry.content, show);
    }
    /**
     * Add a new tab.
     */
    addTab(entry: TabbedContentEntry) {
        if (this.entries.has(entry.id)) {
            throw new Error(`Duplicate entry ids: ${entry.id}`);
        }
        this.entries.set(entry.id, entry);
        this.tabDestroyables.set(entry.id, [
            dojo_on(entry.tab, Input.press, () => this.select(entry.id)),
        ]);
        if (this.focusable) {
            const focusDiv = makeFocusable(
                entry.tab,
                this.focusStyling || "focus-with-space-style",
            );
            this.tabDestroyables.set(entry.id, [
                focusDiv,
                Input.fireCallbackOnKey(focusDiv.node, [Input.ENTER, Input.SPACE], () =>
                    this.select(entry.id),
                ),
            ]);
        }
    }
    /**
     * Remove a tab. If the tab is currently active, select another one.
     */
    removeTab(id: string, toSelect?: string) {
        const entry = this.verifyRemovableEntry(id, "remove");
        if (!entry) {
            return;
        }
        Util.destroy(this.tabDestroyables.get(entry.id));
        this.tabDestroyables.delete(id);
        this.entries.delete(id);
        if (this.currentId === entry.id) {
            // We're deleting the currently-selected tab, so try to select another.
            if (!toSelect || !this.entries.has(toSelect)) {
                toSelect = this.pickActiveEntryOnRemoval();
            }
            this.select(toSelect);
        }
    }
    toggleTab(id: string, enable: boolean): void {
        const entry = this.verifyRemovableEntry(id, enable ? "enable" : "disable");
        if (!entry) {
            return;
        }
        if (enable) {
            Dom.removeClass(entry.tab, "disabled");
        } else if (!Dom.hasClass(entry.tab, "disabled")) {
            if (this.currentId === entry.id) {
                // We're disabling the active tab, so select a new one
                this.select(this.pickActiveEntryOnRemoval());
            }
            Dom.addClass(entry.tab, "disabled");
        }
    }
    showTab(id: string, show = true) {
        const entry = this.verifyRemovableEntry(id, show ? "show" : "hide");
        if (!entry) {
            return;
        }
        if (!show && this.currentId === entry.id) {
            // If we're hiding the active tab, select another one.
            this.select(this.pickActiveEntryOnRemoval());
        }
        Dom.show(entry.tab, show);
    }
    private verifyRemovableEntry(id: string, op: string) {
        const entry = this.entries.get(id);
        if (!entry || this.entries.size === 1) {
            Bugsnag.notify(
                Error(
                    "Attempt to "
                        + op
                        + (!!entry ? "last" : "invalid")
                        + " id "
                        + id
                        + " from tabbed content.",
                ),
            );
            return null;
        }
        return entry;
    }
    private pickActiveEntryOnRemoval() {
        let nextActive: string;
        // Try initial.
        if (this.entries.has(this.params.initial)) {
            nextActive = this.params.initial;
        } else {
            // Just grab the first one in the map.
            this.entries.forEach((e) => {
                if (!nextActive) {
                    nextActive = e.id;
                }
            });
        }
        return nextActive;
    }
    destroy() {
        Util.destroy(this.destroyables);
    }
}

export enum HorizontalTabBarSize {
    /** Standard tab bars for dialogs and such. */
    NORMAL = "normal",
    /** Tab bars for top-of-page control over full page content. */
    LARGE = "large",
}

export interface HorizontalTabBarParams extends TabbedContentParams {
    /** Tab size. Default is NORMAL. */
    size?: HorizontalTabBarSize;
    /**
     * If specified, all tabs will be given this width regardless of their content. If not
     * specified, each tab will be sized to fit its contents.
     */
    tabWidth?: number;
}

/**
 * Subclass that wraps the passed tab nodes in a flexbox and styles them for consistency. Should be
 * used by most places on the platform that need a horizontal tab bar.
 *
 * The "tab" for each entry should just be a string wrapped in a Dom.div().
 */
export class HorizontalTabBar extends TabbedContent {
    node: HTMLElement;
    private lineContainer: HTMLElement;
    private leftLineSegment: HTMLElement;
    private selectedLineSegment: HTMLElement;
    private tabSpacers: Map<string, HTMLElement>;
    private tabGutter: HTMLElement;
    constructor(
        entries: TabbedContentEntry[],
        protected override params: HorizontalTabBarParams = {},
    ) {
        super(entries, params);
    }
    protected override init(params: HorizontalTabBarParams) {
        let firstSelect = true;
        this.tabSpacers = new Map();
        params.size = params.size || HorizontalTabBarSize.NORMAL;
        this.leftLineSegment = Dom.div({ class: "line-segment left-line" });
        this.selectedLineSegment = Dom.div({ class: "line-segment selected-line" });
        this.lineContainer = Dom.div(
            { class: "line-container" },
            this.leftLineSegment,
            this.selectedLineSegment,
            Dom.div({ class: "line-segment right-line" }),
        );
        this.node = Dom.div(
            { class: "horizontal-tabbed-content " + params.size },
            Dom.div(
                { class: "tab-container" },
                (this.tabGutter = Dom.div({ class: "tabbed-content-node tab-gutter" })),
            ),
            this.lineContainer,
        );
        // Wrap the passed onShow to handle the line animation.
        const onShow = params.onShow;
        params.onShow = (tabId: string, oldTabId: string, first: boolean) => {
            if (!oldTabId) {
                // If no tab is currently selected, don't animate anything, just draw the desired border.
                Dom.addClass(this.node, "fixed-border");
            } else {
                // We have a previously selected tab - try and animate the transition between that one
                // and the currently selected one.
                Dom.removeClass(this.node, "fixed-border");
                const linePos = Dom.position(this.lineContainer);
                const lastPos = Dom.position(this.entries.get(oldTabId).tab);
                const currentPos = Dom.position(this.entries.get(this.currentId).tab);
                if (currentPos.w === 0) {
                    // The width of the selected element is 0 for some reason... most likely because
                    // this widget isn't rendered in the dom. Skip the animation!
                    Dom.addClass(this.node, "fixed-border");
                    return;
                }
                // Make sure that the animated line segment starts in the right position.
                Dom.style(this.leftLineSegment, "width", `${lastPos.x - linePos.x}px`);
                Dom.style(this.selectedLineSegment, "width", `${lastPos.w}px`);
                // After these animations complete, we hide the animated lines and switch to using
                // bottom borders on the tabs themselves (which will respond automatically to content
                // changes, etc).
                gsap.to(this.leftLineSegment, {
                    duration: 0.15,
                    width: `${currentPos.x - linePos.x}px`,
                    ease: "power1",
                    onComplete: () => {
                        if (this.currentId === tabId) {
                            Dom.addClass(this.node, "fixed-border");
                        }
                    },
                });
                gsap.to(this.selectedLineSegment, {
                    duration: 0.15,
                    width: `${currentPos.w}px`,
                    ease: "power1",
                    onComplete: () => {
                        if (this.currentId === tabId) {
                            Dom.addClass(this.node, "fixed-border");
                        }
                    },
                });
            }
            onShow && onShow(tabId, oldTabId, first);
        };
    }
    override addTab(entry: TabbedContentEntry) {
        // We wrap each tab in our own div and also add a spacer element to its right.
        const tabNode = Dom.div({ class: "tabbed-content-node tab-node" }, entry.tab);
        const wrappedEntry = {
            id: entry.id,
            tab: tabNode,
            content: entry.content,
        };
        this.params.tabWidth && Dom.style(wrappedEntry.tab, "width", this.params.tabWidth + "px");
        super.addTab(wrappedEntry);
        const spacer = Dom.div({ class: "tabbed-content-node tab-spacer" });
        Dom.place([wrappedEntry.tab, spacer], this.tabGutter, "before");
        this.tabSpacers.set(entry.id, spacer);
    }
    override removeTab(id: string, toSelect?: string) {
        super.removeTab(id, toSelect);
        Dom.destroy(this.tabSpacers.get(id));
        this.tabSpacers.delete(id);
    }
    override showTab(id: string, show = true) {
        super.showTab(id, show);
        Dom.show(this.tabSpacers.get(id), show);
    }
    toggleLineContainer(show: boolean) {
        Dom.show(this.lineContainer, show);
    }
}

export class BorderedHorizontalTabBar extends TabbedContent {
    node: HTMLElement;
    private tabGutter: HTMLElement;

    constructor(
        entries: TabbedContentEntry[],
        protected override params: HorizontalTabBarParams = {},
    ) {
        super(entries, params);
    }

    protected override init(): void {
        this.node = Dom.div(
            { class: "bordered-horizontal-tabbed-content" },
            Dom.div(
                { class: "tab-container" },
                Dom.div({ class: "tab-gutter--left" }),
                (this.tabGutter = Dom.div({ class: "tab-gutter" })),
            ),
        );
    }

    override addTab(entry: TabbedContentEntry): void {
        const tab = Dom.div(
            Dom.div({ class: "tab-node--top-border" }),
            Dom.div({ class: "tab-node--info" }, entry.tab),
        );
        const wrappedEntry = {
            id: entry.id,
            tab,
            content: entry.content,
        };
        this.params.tabWidth && Dom.style(wrappedEntry.tab, "width", this.params.tabWidth + "px");
        super.addTab(wrappedEntry);
        Dom.place(wrappedEntry.tab, this.tabGutter, "before");
    }
}

export function createBorderedTabEntry(
    id: string,
    tabHeaderInfo: Dom.Content,
    content: HTMLElement,
): TabbedContentEntry {
    const tab = Dom.div(
        { class: "bordered-tab-header v-spaced-4" },
        Dom.div(id),
        Dom.div({ class: "bordered-tab-header__content h-spaced-8" }, tabHeaderInfo),
    );
    return { id, tab, content };
}
