import Autocodable = require("Everlaw/Autocodable");
import Base = require("Everlaw/Base");
import { ColorTokens } from "design-system";
import DeleteEntityConfirmation = require("Everlaw/SettingsPage/DeleteEntityConfirmation");
import Document = require("Everlaw/Document"); // circular dependency - use for types only
import Dom = require("Everlaw/Dom");
import ElevatedRoleConfirm = require("Everlaw/ElevatedRoleConfirm");
import Perm = require("Everlaw/PermissionStrings");
import Rest = require("Everlaw/Rest");
import Type = require("Everlaw/Type");
import Typed = require("Everlaw/Typed");
import User = require("Everlaw/User");
import Util = require("Everlaw/Util");
import { Color } from "Everlaw/ColorUtil";
import AutoCodeRule = require("Everlaw/AutoCodeRule");

module Freeform {
    // This should match SUPPORTED_USER_MODIFIABLE_TYPES in MetadataValidationUtil.java
    export const SUPPORTED_TYPES: Type.FieldType[] = [Type.DATE_TIME, Type.NUMBER, Type.TEXT];

    export const codeColor = Color.fromEverColor(ColorTokens.OBJECT_FREEFORM_CODE);
    export type CodeId = number & Base.Id<"FreeformCode">;

    export class Code extends Typed.Field implements Autocodable {
        public override id: CodeId;

        descriptiveText(): HTMLElement {
            return Dom.span(Dom.b(this.display()), " codes");
        }

        designationType(): Autocodable.Type {
            return Autocodable.Type.FREEFORM_CODE;
        }

        get className(): string {
            return "FreeformCode";
        }

        docValue(doc: Document): Value {
            return doc.getFreeformCodeValue(this.id);
        }

        getColor() {
            return codeColor;
        }

        valueFromJson(json: string): Freeform.Value {
            return new Freeform.Value(this, this.getType().fromJsonValue(JSON.parse(json)));
        }
        getDatavisProperty(): string {
            return "freeformCode";
        }
    }

    export class Value extends Typed.Value {
        constructor(
            public code: Code,
            public override value: any,
        ) {
            super();
        }
        getField(): Code {
            return this.code;
        }
    }

    export class ConfirmedActions {
        @ElevatedRoleConfirm("creating a Freeform code")
        static createFreeformCode(name: string, type: Type.FieldType) {
            Rest.post("codes/createFreeformCode.rest", {
                name: name,
                type: type.name,
            }).then((data) => {
                Base.set(Code, data);
            });
        }

        @ElevatedRoleConfirm("renaming a Freeform code")
        static renameFreeformCode(code: Code, name: string, callback?: (code) => void) {
            Rest.post("codes/renameFreeformCode.rest", {
                id: code.id,
                name: name,
            }).then((data) => {
                Base.set(Code, data);
                callback && callback(Base.get(Code, data.id));
            });
        }

        static removeFreeformCode(code: Code, callback?: (code) => void) {
            const performDelete = () => {
                return Rest.post("codes/removeFreeformCode.rest", {
                    id: code.id,
                }).then(() => {
                    Base.remove(code);
                    Base.remove(
                        Base.get("Rule").filter(
                            (rule) => (<AutoCodeRule>rule).designationId === code.id,
                        ),
                    );
                    callback && callback(code);
                });
            };
            import("Everlaw/Property").then((Property) => {
                return DeleteEntityConfirmation.confirmDeletion<Code>(
                    new Property.HasFreeformCode(code).toString(),
                    "freeform code",
                    "value",
                    performDelete,
                );
            });
        }
    }

    export function codeByName(name: string) {
        const result = Base.get(Code).find((ffc) => ffc.name === name);
        if (result) {
            return result;
        }
        const trimmed = name.trim();
        return Base.get(Code).find(
            (ffc) => ffc.name.trim().localeCompare(trimmed, "en", { sensitivity: "base" }) === 0,
        );
    }

    export function getCodes(): Freeform.Code[] {
        return Base.get(Code);
    }

    /**
     * Can the current user read at least one freeform code?
     */
    export function canReadSomeCodes(override = User.Override.NONE) {
        return User.me.hasOverride(override) || getCodes().length > 0;
    }

    /**
     * Can the current user read the given freeform code?
     */
    export function canReadCode(code: Code, override = User.Override.NONE): boolean {
        return User.me.can(Perm.READ, code, override);
    }

    /**
     * Can the current user write at least one freeform code
     */
    export function canWriteSomeCodes(override = User.Override.NONE) {
        return getCodes().some((field) => User.me.can(Perm.WRITE, field, override));
    }

    /**
     * Returns ComboBox.Completions on success, or a string message on error.
     */
    export function autocomplete(
        code: Code,
        val: string,
        maxResults: number,
        includeNoValue = true,
    ) {
        return Typed.autocomplete(val, {
            method: "GET",
            url: "search/autocompleteFreeformCodes.rest",
            params: {
                val,
                code: code.id,
                maxResults,
                includeNoValue,
            },
        });
    }
}

export = Freeform;
